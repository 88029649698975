/**
 * Created by gantushig on 11/6/15.
 */

import {useDispatch, useSelector} from "react-redux";
import {Link, Redirect} from "react-router-dom";
import React, {useEffect, useReducer, useState} from "react";
import {
    changeCompany,
    createNote,
    deleteCompanyDoc,
    downloadCompanyDoc,
    removeNote,
    uploadCompanyDoc
} from "./actions/actions";
import classnames from "classnames";
import dayjs from "dayjs";
import dayjsPluginUTC from 'dayjs-plugin-utc'
import NoteEditMenu from "./NoteEditMenu";
import {ActionTypes, EndpointsExpress, TOKEN} from "./Constants";
import {css} from "@emotion/react";
import axios1 from "./axiosConfig";
import axios from "./axiosConfig";
import ClipLoader from "react-spinners/ClipLoader";
import {toast} from "react-toastify";
import countriesData from "./data/countries1.json"
import CompanyStatusModal from "./components/CompanyStatusModal";

dayjs.extend(dayjsPluginUTC)



const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const initialState = {
    company: {},
    registration_number:"",
    user_login:"",
    notes:[],
    loading: false,
    refresh:false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.GET_COMPANY_REQUEST:
        case ActionTypes.CREATE_USER_REQUEST:
        case ActionTypes.UPDATE_USER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.UPDATE_USER_SUCCESS:
        {
            let c = {...action.company}
            if ('null' != c.joined) {
                c.joined = Date.parse(c.joined);
            }
            if ('null' != c.established) {
                c.established = Date.parse(c.established);
            }
            return {
                ...state,
                company: c,
                registration_number:c.registration_number,
                loading: false
            }
        }
        case ActionTypes.CREATE_USER_SUCCESS:
        {
            let newCompany = {...state.company, login:action.username}
            return {
                ...state,
                company: newCompany,
                loading: false
            }
        }

        case ActionTypes.UPDATE_USER_PWD_SUCCESS:
        {
            let newCompany = {...state.company, password:action.pwd}
            return {
                ...state,
                company: newCompany,
                loading: false
            }
        }
        case ActionTypes.GET_COMPANY_SUCCESS: {
            let c = {...action.company}
            if (c.joined !== null && 'null' != c.joined) {
                c.joined = Date.parse(c.joined); //"2019-06-12T10:20:30Z"); //// dayjs("2019-06-12 00:00:00"); c.issued);
            }
            if (c.established !== null && 'null' != c.established) {
                c.established = Date.parse(c.established); //"2019-06-29T01:00:00Z");//c.established);
            }

            return {
                ...state,
                company: c,
                registration_number:c.registration_number,
                notes:action.notes,
                loading: false
            }
        }
        case 'CHANGE_FIELD':
            let c = {...state.company};
            c[action.name] = action.value
            return {
                ...state,
                company: c,
                loading: false
            }
        case 'SET_COMPANY_REG': {
            let c = {...state.company};
            c.registration_number = state.registration_number
            return {
                ...state,
                company: c,
                loading: false
            }
        }
        case 'CHANGE_REG_NUMBER':
            return {
                ...state,
                registration_number: action.value,
                loading: false
            }

        case 'CHANGE_USER_LOGIN':
            return {
                ...state,
                user_login: action.value,
                loading: false
            }

        case 'REFRESH':
            let r = state.refresh;
            return {
                ...state,
                refresh: !r
            }
        default:
            return state;
    }
};




/*

 USER
 */

const createUserRequest = () => {
    return {
        type: ActionTypes.CREATE_USER_REQUEST
    }
};

const createUserSuccess = (company) => {
    return {
        type: ActionTypes.CREATE_USER_SUCCESS,
        company
    }
};

const createUser = (dispatch, token, companyId) => {
    return new Promise(function (resolve, reject) {
        dispatch(createUserRequest())
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.COMPANIES_V1}/${companyId}/user`;
        config["method"] = "post";

        axios.instance(config)
            .then(response => {

                dispatch(createUserSuccess(response.data.company))
                resolve(response);
            })
            .catch(error => {
                reject(error);

            });
    });
};



const updateUserPwdRequest = () => {
    return {
        type: ActionTypes.UPDATE_USER_PWD_REQUEST
    }
};

const updateUserPwdSuccess = (pwd) => {
    return {
        type: ActionTypes.UPDATE_USER_PWD_SUCCESS,
        pwd
    }
};


export const updateUserPwd = (dispatch, token, companyId) => {
    return new Promise(function (resolve, reject) {

        dispatch(updateUserPwdRequest())

        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.COMPANIES_V1}/${companyId}/pwd`;
        config["method"] = "post";


        axios.instance(config)
            .then(response => {

                dispatch(updateUserPwdSuccess(response.data.password));
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
};


const isEmpty = (obj) => {
    return (obj // 👈 null and undefined check
        && Object.keys(obj).length === 0
        && Object.getPrototypeOf(obj) === Object.prototype)
}

const changeRegNumber = ( value) => {
    return {
        type: 'CHANGE_REG_NUMBER',
        value
    }
}

const changeUserLogin = ( value) => {
    return {
        type: 'CHANGE_USER_LOGIN',
        value
    }
}



const CompanyViewComponent = ({company, notes, onUpdate}) => {

    const [isNoteCreateOpen, setIsNoteCreateOpen] = useState(false);
    const [isNoteDeleteOpen, setIsNoteDeleteOpen] = useState(false);
    const [currNoteId, setCurrNoteId] = useState(0);
    const [note, setNote] = useState("");
    const [sendClicked, setSendClicked] = useState(false);
    const [isCompanyRemoveOpen, setIsCompanyRemoveOpen] = useState(false);
    const [isCompanyTempCloseOpen, setIsCompanyTempCloseOpen] = useState(false);
    const [isCompanyReactivateOpen, setIsCompanyReactivateOpen] = useState(false);
    const [toStatus, setToStatus] = useState("active");
    const [showPasswordClicked, setShowPasswordClicked] = useState(false);
    const [exp, setExp] = useState("");
    const [regMod, setRegMod] = useState(false)
    const [userMod, setUserMod] = useState(false)

    const reduxDispatch = useDispatch();

    const isUpdating = useSelector(state => state.ui.isUpdating);
    const countries = countriesData;



    const token = localStorage.getItem(TOKEN) || null;
    const [state, dispatch] = useReducer(reducer, initialState);
    const {  loading, refresh, registration_number, user_login} = state;

    useEffect(() => {

        if (!isEmpty(company)) {
            if (company.exp_countries){
                let str = company.exp_countries; //'MN,RU,FR,';
                //remove the last comma
                str = str.replace(/,\s*$/, "");

                let test = str.split(','); //['MN','RU','FR'];
                let ret = "";
                test.map(el => {
                    const c = countries.find(e => e.id === el)
                    ret += c.name;
                    ret += ", ";
                })
                if (ret !== "") {
                    ret = ret.replace(/,\s*$/, "");
                    setExp(ret);
                }
            }

        }
        dispatch(changeRegNumber(company.registration_number));
        dispatch(changeUserLogin(company.login));

    }, [company]);

    let modalClass = classnames({
        modal: true,
        'is-active': isNoteDeleteOpen
    });
    let modalCreateNoteClass = classnames({
        modal: true,
        'is-active': isNoteCreateOpen
    });
    const handleDeleteNote = (id) => {
        console.log('cancel');
        setIsNoteDeleteOpen(true)
        setCurrNoteId(id)
    };
    const handleChangeNote = (e) => {
        setNote(e.target.value)
    };

    const handleDeleteNoteYes = () => {
        setIsNoteDeleteOpen(false)
        removeNote(dispatch,  currNoteId).then(response => {
                toast.success("Амжилттай !");
                onUpdate()
                //dispatch(refreshPage());
            }
        ).catch(error => {
            alert(error)
        });
    }
    const handleDeleteNoteCancel = () => {
        setIsNoteDeleteOpen(false)
    }

    const handleCreateNoteYes = () => {

        setIsNoteCreateOpen(false)
        let dto = {
            note
        };
        createNote(dispatch,  company.id, dto).then(response => {
                toast.success("Амжилттай !");
                setNote("");
                onUpdate()
            }
        ).catch(error => {
            alert(error)
        });
    }
    const handleCreateNoteCancel = () => {
        setIsNoteCreateOpen(false)
    }


    let modalCompanyRemoveClass = classnames({
        modal: true,
        'is-active': isCompanyRemoveOpen
    });
    const handleRemoveCompany = () => {
        setIsCompanyRemoveOpen(true)

    }
    const handleCompanyRemoveYes = () => {
        setSendClicked(true);
        let dto = {
            status: 'remove'
        };
        reduxDispatch(changeCompany(token, company.id, dto));
        setIsCompanyRemoveOpen(false)
        setToStatus("removed")

    }
    const handleCompanyRemoveCancel = () => {
        setIsCompanyRemoveOpen(false)
    }


    let modalCompanyTempCloseClass = classnames({
        modal: true,
        'is-active': isCompanyTempCloseOpen
    });
    const handleTempCloseCompany = () => {
        setIsCompanyTempCloseOpen(true)

    }
    const handleCompanyTempCloseYes = () => {
        setSendClicked(true);
        let dto = {
            status: 'temp_close'
        };
        reduxDispatch(changeCompany(token, company.id, dto));
        setIsCompanyTempCloseOpen(false)
        setToStatus("temp_closed")

    }
    const handleCompanyTempCloseCancel = () => {
        setIsCompanyTempCloseOpen(false)
    }


    let modalCompanyReactivateClass = classnames({
        modal: true,
        'is-active': isCompanyReactivateOpen
    });
    const handleCreateNote = (e) => {
        e.preventDefault();
        setIsNoteCreateOpen(true)

    }

    const handleReactivateCompany = () => {
        setIsCompanyReactivateOpen(true)
    }
    const handleCompanyReactivateYes = () => {
        setSendClicked(true);
        let dto = {
            status: 'activate'
        };
        reduxDispatch(changeCompany(token, company.id, dto));

        setIsCompanyReactivateOpen(false)
        setToStatus("active")

    }
    const handleCompanyReactivateCancel = () => {
        setIsCompanyReactivateOpen(false)
    }

    if ((sendClicked && !isUpdating)) {
        return <Redirect to={`/companies/${toStatus}`}/>;
    }

    let items = {}
    if (notes === undefined) {
        items = "";
    } else {
        items = notes.map(
            (p) => {
                return (
                    <tr key={p.id}>
                        <td className="note-item-container">
                            <div className="note-element-container">{p.note} <span><small>{dayjs(p.created).format("MM/DD/YYYY HH:mm")}</small></span>
                            </div>
                            <span><NoteEditMenu id={p.id} onDelete={handleDeleteNote}/></span></td>
                    </tr>
                )
            });
    }

    const handleLetterFileOnChange = (e) => {
        const file = e.target.files[0];
        reduxDispatch(uploadCompanyDoc(token, company.id, file, 1))
    };
    const handleDocFileOnChange = (e) => {
        const file = e.target.files[0];
        reduxDispatch(uploadCompanyDoc(token, company.id, file, 2))
    };
    const handleDocBacksideFileOnChange = (e) => {
        const file = e.target.files[0];
        reduxDispatch(uploadCompanyDoc(token, company.id, file, 3));
    };


    const handleDownloadDoc = (s) => {
        reduxDispatch(downloadCompanyDoc(token, company.id, s));
    };
    const handleDeleteDoc = (s) => {
        reduxDispatch(deleteCompanyDoc(token, company.id, s))
    };
    const handleCreateUser = () => {
        createUser(dispatch, token, company.id).then(response => {
                toast.success("Амжилттай !");
                onUpdate();
            }
        ).catch(error => {
            alert(error)
        });
    };
    const handleShowPassword = () => {
        setShowPasswordClicked(true);
    };
    const handleChangePassword = () => {
        updateUserPwd(dispatch, token, company.id).then(response => {
                toast.success("Амжилттай !");
                onUpdate();
            }
        ).catch(error => {
            alert(error)
        });
    };

    const handleModify = ()=> {
        setRegMod(true);
    }
    const handleModifyYes = ()=> {
        setRegMod(false);
        const changeReg = (info)=> {
            let header = {}
            header["Accept"] = "application/json"
            header["Content-Type"] = "application/json"

            let config = {}
            config["headers"] = header;
            config["url"] = `${EndpointsExpress.COMPANY_ADMIN}/${company.id}/change_registry`;
            config["method"] = "post";
            config["data"] = JSON.stringify(info);

            axios1.instance(config)
                .then(response => {
                    toast.success("Success")
                    onUpdate()
                })
                .catch(error => {
                    alert("Error")
                });
        }
        let dto = {};
        dto.registration_number = registration_number;
        changeReg(dto);
    }
    const handleModifyNo = ()=> {
        setRegMod(false);
    }
    const handleChangeRegNumber = (e) => {
        dispatch(changeRegNumber(e.target.value))
    }


    // user login
    const handleChangeUserName = ()=> {
        setUserMod(true);
    }
    const handleChangeUserNameYes = ()=> {
        setUserMod(false);
        const changeUserLogin = (info)=> {
            let header = {}
            header["Accept"] = "application/json"
            header["Content-Type"] = "application/json"

            let config = {}
            config["headers"] = header;
            config["url"] = `${EndpointsExpress.COMPANY_ADMIN}/${company.id}/change_user_login`;
            config["method"] = "post";
            config["data"] = JSON.stringify(info);

            axios1.instance(config)
                .then(response => {
                    toast.success("Success")
                    onUpdate()
                })
                .catch(error => {
                    alert("Error")
                });
        }
        let dto = {};
        dto.login = user_login;
        changeUserLogin(dto);
    }
    const handleChangeUserNameNo = ()=> {
        setUserMod(false);
    }
    const handleModifyUserName = (e) => {
        dispatch(changeUserLogin(e.target.value))
    }

    return (
        <div>
            <div className="buttons has-addons is-right" style={{marginBottom: "0px"}}>
                <Link to={`/company/${company.id}/edit`} className="button">
                    Өөрчлөх
                </Link>
                {company.status === "ACTIVE" &&
                <a href="#" className="button" onClick={() => handleTempCloseCompany()}>Түр Хаах</a>
                }
                {company.status === "ACTIVE" &&
                <a href="#" className="button" onClick={() => handleRemoveCompany()}>Хасах</a>
                }
                {company.status !== "ACTIVE" &&
                <a href="#" className="button" onClick={() => handleReactivateCompany()}>Сэргээх</a>
                }
            </div>
            <ClipLoader color="#ffffff" loading={loading} css={override} size={150}/>
            <table className="table is-fullwidth is-bordered">
                <tbody>
                <tr>
                    <th className="company-title">Нэр</th>
                    <td className="company-value">{company && company.name}</td>
                </tr>
                <tr>
                    <th className="company-title">Нэр Англиар</th>
                    <td className="company-value">{company && company.name_english}</td>
                </tr>
                <tr>
                    <th className="company-title">Улсын бүртгэлийн дугаар</th>
                    <td className="company-value">{company && company.state_reg_number}</td>
                </tr>
                <tr>
                    <th className="company-title">Регистрийн дугаар</th>
                    <td className="company-value">
                            {!regMod &&
                                <>
                                {company && company.registration_number}
                                <button className="ml-6 border p-3" onClick={(e) => handleModify(e)}>Регистр өөрчлөх</button>
                                </>
                            }
                            {regMod &&
                                <div>
                                    <input className="w-40 border p-3" name="registration_number" type="text" value={registration_number}
                                           onChange={(e) => handleChangeRegNumber(e)} placeholder="Регистрийн дугаар оруулах"/>
                                    <button className="border p-3 bg-green-500" onClick={(e) => handleModifyYes(e)}>
                                        Yes
                                        </button>
                                    <button className="border p-3" onClick={(e) => handleModifyNo(e)}>No</button>
                                </div>
                            }
                    </td>
                </tr>
                <tr>
                    <th>Байгуулагдсан огноо</th>
                    <td>{company && company.established && dayjs.utc(company.established).format("MM/DD/YYYY")}</td>
                </tr>
                <tr>
                    <th>Элссэн огноо</th>
                    <td>{company && company.joined && dayjs.utc(company.joined).format("MM/DD/YYYY")}</td>
                </tr>
                <tr>
                    <th>Аймаг/Хот</th>
                    <td>{company && company.aimak_str}</td>
                </tr>
                <tr>
                    <th>Сум / Дүүрэг</th>
                    <td>{company && company.district_str}</td>
                </tr>
                <tr>
                    <th>Баг / Хороо</th>
                    <td>{company && company.soum}</td>
                </tr>
                <tr>
                    <th>Гудамж/Тоот</th>
                    <td>{company && company.address}</td>
                </tr>
                <tr>
                    <th>Утас</th>
                    <td>{company && company.phone}</td>
                </tr>
                <tr>
                    <th>Шуудангийн хайрцаг</th>
                    <td>{company && company.postal_address}</td>
                </tr>
                <tr>
                    <th>Мэйл</th>
                    <td>
                        {company &&
                        <a href={`mailto:${company.email}`}>{company.email}</a>
                        }
                        </td>
                </tr>
                <tr>
                    <th>Сошиал хаяг</th>
                    <td>{company && company.social}</td>
                </tr>
                <tr>
                    <th>Вебхуудас</th>
                    <td>{company && company.web}</td>
                </tr>
                <tr>
                    <th>Үйл ажиллагааны чиглэл</th>
                    <td>{company && company.sector_name}</td>
                </tr>
                <tr>
                    <th>Зип код</th>
                    <td>{company && company.zipcode}</td>
                </tr>
                <tr>
                    <th>Шуудангийн код</th>
                    <td>{company && company.postal_code}</td>
                </tr>
                <tr>
                    <th>Экспорт хийдэг эсэх</th>
                    <td><input type="checkbox" disabled
                               checked={company && company.is_exported}/></td>
                </tr>
                <tr>
                    <th>Тийм бол экспорт хийж буй улсын нэр/ Үгүй бол хийхээр төлөвлөж байгаа улс /Компани/</th>
                    <td>{company && company.is_exported_countries}</td>
                </tr>
                <tr>
                    <th>Тийм бол экспорт хийж буй улсын нэр/ Үгүй бол хийхээр төлөвлөж байгаа улс /gs1mn/</th>
                    <td>{exp}</td>
                </tr>

                <tr>
                    <th>Албан Тоот</th>
                    <td>
                        { company && !company.exists_letter_att &&
                        <div className="file">
                            <label className="file-label">
                                <input className="file-input" type="file" name="resume"
                                       onChange={(e) => handleLetterFileOnChange(e)} multiple={false}/>
                                <span className="file-cta">
                                      <span className="file-icon">
                                            <i className="fas fa-upload"></i>
                                      </span>
                                      <span className="file-label">
                                            Файл сонгох…
                                      </span>
                                </span>
                            </label>
                        </div>
                        }
                        { company && company.exists_letter_att &&
                        <div>
                            <a onClick={(e) => handleDownloadDoc(1)}>{company.letter_file_name}</a>
                            <a onClick={(e) => handleDeleteDoc(1)} className="delete"></a>
                        </div>
                        }
                    </td>
                </tr>
                <tr>
                    <th>Гэрчилгээ/Үнэмлэх</th>
                    <td>
                        { company && !company.exists_doc_att &&
                        <div className="file">
                            <label className="file-label">
                                <input className="file-input" type="file" name="resume"
                                       onChange={(e) => handleDocFileOnChange(e)} multiple={false}/>
                                <span className="file-cta">
                                      <span className="file-icon">
                                            <i className="fas fa-upload"></i>
                                      </span>
                                      <span className="file-label">
                                            Файл сонгох…
                                      </span>
                                </span>
                            </label>
                        </div>
                        }
                        { company && company.exists_doc_att &&
                        <div>
                            <a onClick={(e) => handleDownloadDoc(2)}>{company.doc_file_name}</a>
                            <a onClick={(e) => handleDeleteDoc(2)} className="delete"></a>
                        </div>
                        }

                    </td>
                </tr>

                <tr>
                    <th>Гэрчилгээ/Үнэмлэх / Арын Хуудас/ </th>
                    <td>
                        { company && !company.exists_doc2_att &&
                        <div className="file">
                            <label className="file-label">
                                <input className="file-input" type="file" name="resume"
                                       onChange={(e) => handleDocBacksideFileOnChange(e)} multiple={false}/>
                                <span className="file-cta">
                                      <span className="file-icon">
                                            <i className="fas fa-upload"></i>
                                      </span>
                                      <span className="file-label">
                                            Файл сонгох…
                                      </span>
                                </span>
                            </label>
                        </div>
                        }
                        { company && company.exists_doc2_att &&
                        <div>
                            <a onClick={(e) => handleDownloadDoc(3)}>{company.doc2_file_name}</a>
                            <a onClick={(e) => handleDeleteDoc(3)} className="delete"></a>
                        </div>
                        }

                    </td>
                </tr>
                <tr>
                    <th className="company-title">Certificate</th>
                    <td className="company-value"><input type="checkbox" disabled
                                                         checked={company && company.is_certificate}/></td>
                </tr>
                <tr>
                    <th className="company-title">GEPIR</th>
                    <td className="company-value"><input type="checkbox" disabled
                                                         checked={company && company.is_gepir}/></td>
                </tr>

                <tr>
                    <th>User</th>
                    <td>
                        { company && !company.login &&
                        <div>
                            <a className="button" onClick={(e) => handleCreateUser()}>Байгууллагын Хэрэглэгч Үүсгэх</a>
                        </div>
                        }
                        { company && company.login &&
                        <div>
                            {!userMod &&
                                <>
                                    {company && company.login}
                                    <button className="ml-6 border p-3" onClick={(e) => handleChangeUserName(e)}>User өөрчлөх</button>
                                </>
                            }
                            {userMod &&
                                <div>
                                    <input className="w-40 border p-3" name="user_login" type="text" value={user_login}
                                           onChange={(e) => handleModifyUserName(e)} placeholder="User login оруулах"/>
                                    <button className="border p-3 bg-green-500" onClick={(e) => handleChangeUserNameYes(e)}>
                                        Yes
                                    </button>
                                    <button className="border p-3" onClick={(e) => handleChangeUserNameNo(e)}>No</button>
                                </div>
                            }

                        </div>
                        }
                    </td>
                </tr>
                <tr>
                    <th>Password</th>
                    <td>
                        { company && company.password && !showPasswordClicked &&
                        <div>
                            <a className="button" onClick={(e) => handleShowPassword()}>Нууц Үг Харуулах</a>
                        </div>
                        }
                        { company && company.password && showPasswordClicked &&
                        <div>
                            {company.password} <a className="button" onClick={(e) => handleChangePassword()}>Нууц Үг Өөрчлөх</a>
                        </div>
                        }
                    </td>
                </tr>


                <tr>
                    <th>Илгээгч: Нэр</th>
                    <td>
                        {company.sender_name}
                    </td>
                </tr>
                <tr>
                    <th>Илгээгч: Албан тушаал</th>
                    <td>
                        {company.sender_position}
                    </td>

                </tr>
                <tr>
                    <th>Илгээгч: Утас</th>
                    <td>
                        {company.sender_phone}
                    </td>

                </tr>
                <tr>
                    <th>Илгээгч: Емайл</th>
                    <td>
                        {company.sender_email}
                    </td>
                </tr>

                </tbody>
            </table>



            <div className="buttons is-right">
                <a className="button" onClick={(e) => handleCreateNote(e)}>Шинээр Тэмдэглэл Үүсгэх</a>
            </div>
            <h1 style={{marginBottom:"20px"}}>Тэмдэглэлүүд</h1>
            <table className="table is-bordered is-fullwidth">
                <tbody>
                {items}
                </tbody>
            </table>

            <div className={modalCreateNoteClass}>
                <div className="modal-background"></div>
                <div className="modal-content" style={{borderRadius: "12px", padding:"20px", backgroundColor:"white"}}>

                        <div className="field">

                            <div className="control has-icons-left">
                            <textarea className="textarea" type="text" value={note}
                                      onChange={(e) => handleChangeNote(e)} placeholder="Тэмдэглэл оруулах"/>
                            </div>
                        </div>
                    <div className="buttons is-right">

                        <button className="button" onClick={handleCreateNoteCancel}>Болих</button>
                        <button className="button is-success" onClick={handleCreateNoteYes}>Хадгалах</button>
                    </div>
                </div>
            </div>

            <CompanyStatusModal isOpen={isNoteDeleteOpen}
                                title={"Устгах?"}
                                question="Та тэмдэглэл  устгахдаа итгэлтэй байна уу?"
                                onConfirm={handleDeleteNoteYes} onCancel={handleDeleteNoteCancel}/>
            <CompanyStatusModal isOpen={isCompanyRemoveOpen}
                                title={"Хасах?"}
                                question="Та хасахдаа итгэлтэй байна уу?"
                                onConfirm={handleCompanyRemoveYes} onCancel={handleCompanyRemoveCancel}/>
            <CompanyStatusModal isOpen={isCompanyTempCloseOpen}
                                title={"Түр Хаах?"}
                                question="Та түр хаахдаа итгэлтэй байна уу?"
                                onConfirm={handleCompanyTempCloseYes} onCancel={handleCompanyTempCloseCancel}/>
            <CompanyStatusModal isOpen={isCompanyReactivateOpen}
                                title={"Сэргээх?"}
                                question="Та сэргээхдээ итгэлтэй байна уу?"
                                onConfirm={handleCompanyReactivateYes} onCancel={handleCompanyReactivateCancel}/>
        </div>
    )

}

export default CompanyViewComponent;




