/**
 * Created by gantushig on 4/16/17.
 */

import React, {useReducer} from "react";
import {useHistory} from "react-router-dom";
import {toast} from 'react-toastify';
import {EndpointsExpress} from "./Constants";
import {css} from "@emotion/react";
import axios from './axiosConfig'
import ClipLoader from "react-spinners/ClipLoader";
import SmsMassEditComponent from "./SmsMassEditComponent";
import {useSmsMassContext} from "./context/SmsMassContext";


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const initialState = {
    company: {name:"", sms:"", sms2:"", sms3:"", length:0, length2:0, length3:0, send_audience:"ALL"},
    loading: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'CHANGE_FIELD':
            const updatedCompany = { ...state.company, [action.name]: action.value };
            ["sms", "sms2", "sms3"].forEach((field, index) => {
                if (action.name === field) {
                    updatedCompany[`length${index === 0 ? "" : index + 1}`] = action.value.length;
                }
            });
            return { ...state, company: updatedCompany };
        case 'SET_LOADING':
            return { ...state, loading: action.loading };
        default:
            return state;
    }
};

const changeField = (name, value) => ({ type: 'CHANGE_FIELD', name, value });
const setLoading = (loading) => ({ type: 'SET_LOADING', loading });


const SmsMassCreate = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { company, loading } = state;
    const history = useHistory();
    const { addItem } = useSmsMassContext();

    const handleSave = async () => {
        dispatch(setLoading(true));
        const dto = {
            name: company.name,
            sms: company.sms,
            sms2: company.sms2,
            sms3: company.sms3,
            send_audience: company.send_audience
        };
        const config = {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            url: `${EndpointsExpress.SMS_MASS_BODIES}`,
            method: "post",
            data: JSON.stringify(dto)
        };
        try {
            const response = await axios.instance(config);
            addItem(response.data); // Add the new item to the context
            toast.success("Sms created successfully!");
            history.push("/sms-mass-bodies");
        } catch (error) {
            toast.error("Failed to create Sms, please try again.");
            console.error(error);
        } finally {
            dispatch(setLoading(false));
        }
    };

    const handleChangeField = (e) => dispatch(changeField(e.target.name, e.target.value));
    const handleCancel = () => history.goBack();

    return (
        <div className="inner-container">
            <div className="level">
                <div className="level-left" style={{ paddingLeft: '10px', paddingTop: '10px' }}>
                    <h1 className="title is-size-4 has-text-info">Add Information</h1>
                </div>
            </div>
            <hr />
            <ClipLoader color="#ffffff" loading={loading} css={override} size={150} />
            <SmsMassEditComponent
                obj={company}
                onChangeField={handleChangeField}
                onSave={handleSave}
                onCancel={handleCancel}
            />
        </div>
    )
}


export default SmsMassCreate;