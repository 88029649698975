/**
 * Created by gantushig on 4/16/17.
 */

import React from "react";
import "react-datepicker/dist/react-datepicker.css";

const SmsMassEditComponent = ({ obj, onChangeField, onSave, onCancel }) => {
    const handleSave = () => onSave();

    const renderTextareaField = (label, name, value, placeholder, length) => (
        <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
                {label} <span className="text-sm font-light">[ {length} үсэг]</span>
            </label>
            <div className="relative">
        <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name={name}
            value={value}
            onChange={onChangeField}
            placeholder={placeholder}
            rows="3"
        />
            </div>
        </div>
    );

    return (
        <div className="bg-white p-6 rounded-lg shadow-md">
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Нэр</label>
                <div className="relative">
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        name="name"
                        type="text"
                        value={obj.name}
                        onChange={onChangeField}
                        placeholder="Нэр оруулах"
                    />
                </div>
            </div>

            {renderTextareaField("Sms (Mobicom)", "sms", obj.sms, "Sms оруулах (Англиар)", obj.length)}
            {renderTextareaField("Sms (Unitel)", "sms2", obj.sms2, "Sms оруулах", obj.length2)}
            {renderTextareaField("Sms (Skytel)", "sms3", obj.sms3, "Sms оруулах", obj.length3)}

            {/*<div className="mb-4">*/}
            {/*    <label className="block text-gray-700 text-sm font-bold mb-2">Илгээх</label>*/}
            {/*    <div className="relative">*/}
            {/*        <select*/}
            {/*            className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"*/}
            {/*            value={obj.send_audience}*/}
            {/*            name="send_audience"*/}
            {/*            onChange={onChangeField}*/}
            {/*        >*/}
            {/*            <option value="ALL">All</option>*/}
            {/*            <option value="SELECTED">Selected</option>*/}
            {/*        </select>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="flex justify-end pt-2">
                <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded mr-2" onClick={onCancel}>Буцах</button>
                <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" onClick={handleSave}>Хадгалах</button>
            </div>
        </div>
    );
};

export default SmsMassEditComponent;