/**
 * Created by gantushig on 4/18/19.
 */

import {ActionTypes, Endpoints, EndpointsExpress} from "../Constants";
//import util from "util";
import   axios from '../axiosConfig'
import   {axiosNoConfig} from '../axiosNoInterceptorsConfig'
import  Cookies  from 'js-cookie';
import FileSaver from "file-saver";

const FileDownload = require('js-file-download');


function requestLogin(creds) {
    return {
        type: ActionTypes.LOGIN_REQUEST,
        payload: {
            isFetching: true,
            isAuthenticated: false,
            creds
        }
    }
}


function receiveLogin(token) {
    return {
        type: ActionTypes.LOGIN_SUCCESS,
        payload: {
            isFetching: false,
            isAuthenticated: true,
            id_token: token
        }
    }
}

function loginError(message) {
    return {
        type: ActionTypes.LOGIN_FAILURE,
        payload: {
            isFetching: false,
            isAuthenticated: false,
            message
        },
        error: true
    }
}

export const loginUser = (creds) => dispatch =>

    new Promise(function (resolve, reject) {

        // We dispatch requestLogin to kickoff the call to the API
        //dispatch(requestLogin(creds))
        let config = {
            method: 'post',
            url: Endpoints.AUTH_SIGNIN_URL,
            data: creds//,
            //headers: {'Content-Type': 'multipart/form-data' }
        }

        let axiosconfig = {}
        const headers = new Headers();
        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");
        axiosconfig["headers"] = headers;
        axiosconfig["data"] = JSON.stringify(creds)
        axiosconfig["url"] = Endpoints.AUTH_SIGNIN_URL;
        axiosconfig["method"] = 'post';

        axios.instance(config)
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }

                const token = response.data.token;
                localStorage.setItem('id_token', "Bearer " + token)
                dispatch(receiveLogin(token))
                resolve(response);
            })
            .catch( err => {
                    console.log("Error: ", err);
                    reject(err)
                }
            )
    });



function receiveCheckLogin() {
    return {
        type: ActionTypes.CHECK_LOGIN_SUCCESS
    }
}

export const checkLoginExpress = () => dispatch =>

    new Promise(function (resolve, reject) {

        let config = {
            method: 'post',
            url: EndpointsExpress.CHECK,
        }

        axios.instance(config)
            .then(function (response) {
                localStorage.setItem('auth_express', "true")
                dispatch(receiveCheckLogin())
                resolve(response);
            })
            .catch( err => {
                    console.log("Error: ", err);
                    reject(err)
                }
            )
    });



function receiveLoginExpress() {
    return {
        type: ActionTypes.LOGIN_EXPRESS_SUCCESS,
        payload: {
            isFetching: false,
            isAuthenticated: true,
            isAuthenticatedExpress: true,
        }
    }
}
export const loginUserExpress = (creds) => {


    return (dispatch) => {
        let config = {
            method: 'post',
            url: EndpointsExpress.LOGIN,
            data: creds,
            withCredentials: true // Allow cookies to be sent/received
        }

        let axiosconfig = {}
        const headers = new Headers();
        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");
        axiosconfig["headers"] = headers;
        axiosconfig["data"] = JSON.stringify(creds)
        axiosconfig["url"] = EndpointsExpress.LOGIN;
        axiosconfig["method"] = 'post';

        return axios.instance(config)
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                localStorage.setItem('auth_express', "true")
                dispatch(receiveLoginExpress())

            })
            .catch(
                err => console.log("Error: ", err)
            )
    }
}


// Calls the API to get a token and
// dispatches actions along the way
export const loginAccEmail = (username, password) => {

    let rusername = username.replace(/\+/g, '%2B');
    let rpassword = password.replace(/\+/g, '%2B');

    let endpoint = Endpoints.LOGINACC_URL;
    endpoint += "?username=";
    endpoint += rusername;
    endpoint += "&password=";
    endpoint += rpassword;

    return (dispatch) => {
        // We dispatch requestLogin to kickoff the call to the API
        //dispatch(requestLogin(creds))

        let header = {};

        header["Accept"] = "application/json";
        header["Content-Type"] = "application/json";

        let config = {};
        config["headers"] = header;
        config["url"] = endpoint;

        return axios.instance(config)
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                if (!response.status == 200) {
                    // If there was a problem, we want to
                    // dispatch the error condition
                    dispatch(loginError("error"))
                    //return Promise.reject(user)
                } else {
                    //console.log(util.inspect(response, false, null));
                    const token = response.headers['authorization']
                    // If login was successful, set the token in local storage
                    localStorage.setItem('id_token', token)
                    //forward
                    // Dispatch the success action
                    dispatch(receiveLogin(token))
                }
            })
            .catch(
                err => console.log("Error: ", err)
            )
    }
}


// Three possible states for our logout process as well.
// Since we are using JWTs, we just need to remove the token
// from localStorage. These actions are more useful if we
// were calling the API to log the user out

// Logs the user out
export const logoutUser = () => {
    return dispatch => {
        dispatch(requestLogout())
        localStorage.removeItem('id_token');
        localStorage.removeItem('auth_express');
        Cookies.remove('_gs1mn',{ path: '' });
        dispatch(logoutSuccess())
    }
};


function requestLogout() {
    return {
        type: ActionTypes.LOGOUT_REQUEST,
        payload: {
            isFetching: true,
            isAuthenticated: true
        }
    }
}

export const logoutSuccess = () => {
    return {
        type: ActionTypes.LOGOUT_SUCCESS,
        payload: {
            isFetching: false,
            isAuthenticated: false
        }
    }
};


/*
 *
 * Dashboard
 *
 *
 */


export const fetchDashboardRequest = () => {
    return {
        type: ActionTypes.GET_DASHBOARD_REQUEST
    }
};

export const fetchDashboard = (token) => {

    return (dispatch) => {
        dispatch(fetchDashboardRequest())
        let header = {};
        header["Authorization"] = token;
        header["Accept"] = "application/json";
        header["Content-Type"] = "application/json";

        let config = {};
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.DASHBOARD}`;

        return axios.instance(config)
            .then(response => {
                dispatch(fetchDashboardSuccess(response.data.company_active, response.data.company_temp_closed, response.data.company_removed,
                    response.data.gcp, response.data.gtin, response.data.invoice,
                    response.data.product_appl, response.data.company_appl
                ))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
//                throw(error);
            });
    };
}


export const fetchDashboardSuccess = (companyActive, companyTempClosed, companyRemoved, gcp, gtin, invoice, productAppl, companyAppl) => {
    return {
        type: ActionTypes.GET_DASHBOARD_SUCCESS,
        companyActive, companyTempClosed, companyRemoved, gcp, gtin, invoice, productAppl, companyAppl
    }
};





/*
 *
 * Dashboard
 *
 *
 */


export const fetchAimaksRequest = () => {
    return {
        type: ActionTypes.FETCH_AIMAKS_REQUEST
    }
};

export const fetchAimaks = (dispatch) => {

        dispatch(fetchAimaksRequest())
        let header = {};
        header["Accept"] = "application/json";
        header["Content-Type"] = "application/json";

        let config = {};
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.AIMAKS}`;

        return axios.instance(config)
            .then(response => {
                dispatch(fetchAimaksSuccess(response.data.aimaks
                ))
            })
            .catch(error => {
                throw(error);
            });

}


export const fetchAimaksSuccess = (aimaks) => {
    return {
        type: ActionTypes.FETCH_AIMAKS_SUCCESS,
        aimaks
    }
};


/*
 *
 * COMPANY PAGE
 *
 *
 */

const fetchCompaniesRequest = (name, phone, email, regnumber, status, aimak, district, sector, joinedYear, joinedMonth, isExported, isCertificate, isGepir, page, pagesize, orderField, isAsc) => {
    return {
        type: ActionTypes.LOAD_COMPANIES_REQUEST,
        name,
        phone,
        email,
        regnumber,
        status,
        aimak,
        district,
        sector,
        joinedYear,
        joinedMonth,
        isExported,
        isCertificate,
        isGepir,
        page,
        pagesize,
        orderField,
        isAsc
    }
};


const fetchCompaniesSuccess = (companies, meta) => {
    return {
        type: ActionTypes.LOAD_COMPANIES_SUCCESS,
        companies,
        meta
    }
};

export const fetchCompanies = (dispatch, token, name, phone, contact_email, email, regnumber, status, aimak, district, sector, joinedYear, joinedMonth,
                        isExported, isCertificate, isGepir, page, pagesize, order, asc) => {


    dispatch(fetchCompaniesRequest(name, phone, email, regnumber, status, aimak, district, sector, joinedYear, joinedMonth, isExported, isCertificate, isGepir, page, pagesize, order, asc));
    let header = {};
    header["Authorization"] = token;
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.COMPANIES}/?status=${status}&name=${name}&phone=${phone}&contact_email=${contact_email}&email=${email}&registration_number=${regnumber}&aimak=${aimak}&district=${district}&sector=${sector}&joined_year=${joinedYear}&joined_month=${joinedMonth}&is_exported=${isExported}&is_certificate=${isCertificate}&is_gepir=${isGepir}&page=${page}&pagesize=${pagesize}&order=${order}&asc=${asc}`;

    return axios.instance(config)
        .then(response => {

            dispatch(fetchCompaniesSuccess(response.data.items, response.data.meta))
        })
        .catch(error => {
            //dispatch(finishRequest())
            // if (error.response === undefined) {
            //     dispatch(failure("Error", error.message));
            // } else {
            //     dispatch(failure(error.response.data.error, error.response.data.message));
            // }
            throw(error);
        });

}


/*
 *
 * COMPANY
 *
 *
 */

export const resetCompaniesSearch = () => {
    return {
        type: ActionTypes.RESET_COMPANIES_SEARCH
    }
};

export const setOrderCompaniesSearch = (orderField) => {
    return {
        type: ActionTypes.LOAD_COMPANIES_SET_ORDER,
        orderField
    }
};


export const printCompaniesRequest = () => {
    return {
        type: ActionTypes.PRINT_COMPANIES_REQUEST
    }
};

export const printCompanies = (token, name, phone, email, regnumber, status, aimak, district, sector, joinedYear, joinedMonth,
                               isExported, isCertificate, isGepir, order, asc) => {

    return (dispatch) => {
        dispatch(printCompaniesRequest());
        let header = {};
        header["Authorization"] = token;
        header["Accept"] = "application/json";
        header["Content-Type"] = "application/json";

        let config = {};
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.COMPANIES_V1}/print/?status=${status}&name=${name}&phone=${phone}&email=${email}&regnumber=${regnumber}&aimak=${aimak}&district=${district}&sector=${sector}&joined_year=${joinedYear}&joined_month=${joinedMonth}&is_exported=${isExported}&is_certificate=${isCertificate}&is_gepir=${isGepir}&order=${order}&asc=${asc}`;
        config["responseType"] = "arraybuffer";

        return axios.instance(config)
            .then(response => {


                dispatch(printCompaniesSuccess())

                const fileNameHeader = "x-suggested-filename";
                const suggestedFileName = response.headers[fileNameHeader]
                let fn = decodeURIComponent(suggestedFileName);

                var bytes = new Uint8Array(response.data);

                var blob = new Blob([bytes], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                FileSaver.saveAs(blob, fn)


            })
            .catch(error => {
                //dispatch(finishRequest())
                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
//                throw(error);
            });
    };
}

export const printCompaniesSuccess = () => {
    return {
        type: ActionTypes.PRINT_COMPANIES_SUCCESS,
    }
};


export const companiesPageSelected = (page) => {
    return {
        type: ActionTypes.COMPANIES_PAGE_SELECTED,
        page
    }
};


const fetchCompanyRequest = () => {
    return {
        type: ActionTypes.GET_COMPANY_REQUEST
    }
};

const fetchCompanySuccess = (status, company, notes) => {
    return {
        type: ActionTypes.GET_COMPANY_SUCCESS,
        status,
        company,
        notes
    }
};

export const fetchCompany = (dispatch,  companyId) => {

    dispatch(fetchCompanyRequest())
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.COMPANY}/${companyId}`;

    return axios.instance(config)
        .then(response => {

            dispatch(fetchCompanySuccess(response.data.status, response.data.company, response.data.notes))
        })
        .catch(error => {

            throw(error);

        });
}



export const setUiTitle = (title) => {
    return {
        type: ActionTypes.SET_UI_TITLE,
        title
    }
};

export const setGcpPrefix = (gcp_prefix) => {
    return {
        type: ActionTypes.SET_GCP_PREFIX,
        gcp_prefix
    }
};


export const uploadCompanyDocRequest = () => {
    return {
        type: ActionTypes.UPLOAD_COMPANY_DOC_REQUEST
    }
};

export function uploadCompanyDoc(token, id, file, side) {

    return dispatch => {
        dispatch(uploadCompanyDocRequest())
        let url = `${EndpointsExpress.COMPANIES_V1}/${id}/upload`;
        let data = new FormData();
        data.append('file', file)
        data.append('side', side)

        let config = {};
        config["Authorization"] = token;
        config['Content-Type'] = 'multipart/form-data';
        return axios.instance.post(url, data, {headers: config})
            .then(function (response) {

                dispatch(uploadCompanyDocSuccess(response.data.company))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
//                throw(error);
            });
    }
}


export const uploadCompanyDocSuccess = (company) => {
    return {
        type: ActionTypes.UPLOAD_COMPANY_DOC_SUCCESS,
        company
    }
};


export const downloadCompanyDocRequest = () => {
    return {
        type: ActionTypes.DOWNLOAD_COMPANY_DOC_REQUEST
    }
};

// dispatch(downloadCompanyDocRequest())
// try {
//     // Get download URL and filename
//     const { data: { downloadUrl, filename } } = await axios.instance.get(
//         `${EndpointsExpress.COMPANIES_V1}/${id}/doc/${side}`
//     );
//
//     // Fetch the file blob using native fetch
//     const response = await fetch(downloadUrl);
//     const blob = await response.blob();
//
//     dispatch(downloadCompanyDocSuccess())
//     // Trigger download
//     FileDownload(blob, filename);
//
// } catch (error) {
//     dispatch(failure("Error", error.message));
//     throw error;
// }

export const downloadCompanyDoc = (token, id, side) => async (dispatch) => {
    dispatch(downloadCompanyDocRequest());

    try {
        const { data: { downloadUrl, filename } } = await axios.instance.get(
            `${EndpointsExpress.COMPANIES_V1}/${id}/doc/${side}`
        );

        // Fetch the file blob using native fetch
        const response = await fetch(downloadUrl);
        const blob = await response.blob();

        FileDownload(blob, filename);
        dispatch(downloadCompanyDocSuccess());
    } catch (error) {
        if (!error.response) {
            dispatch(failure('Error', error.message));
        } else {
            dispatch(failure(error.response.data.error, error.response.data.message));
        }
    }
};

// export async function downloadCompanyDoc(token, id, side) {
//
//     return (dispatch) => {
//         dispatch(downloadCompanyDocRequest())
//         let header = {};
//         header["Authorization"] = token;
//
//         let config = {};
//         config["headers"] = header;
//         config["url"] = `${EndpointsExpress.COMPANIES_V1}/${id}/doc/${side}`;
//         config["responseType"] = "arraybuffer";
//
//         return axios.instance(config)
//             .then(response => {
//                 dispatch(downloadCompanyDocSuccess())
//                 const fileNameHeader = "x-suggested-filename";
//                 const suggestedFileName = response.headers[fileNameHeader]
//                 let fn = decodeURIComponent(suggestedFileName);
//
//                 FileDownload(response.data, fn)
//
//             })
//             .catch(error => {
//
//                 if (error.response === undefined) {
//                     dispatch(failure("Error", error.message));
//                 } else {
//                     dispatch(failure(error.response.data.error, error.response.data.message));
//                 }
// //                throw(error);
//             });
//     };
//
// }

export const downloadCompanyDocSuccess = () => {
    return {
        type: ActionTypes.DOWNLOAD_COMPANY_DOC_SUCCESS
    }
};


export const downloadCompanyApplDocRequest = () => {
    return {
        type: ActionTypes.DOWNLOAD_COMPANY_APPL_DOC_REQUEST
    }
};

export const downloadCompanyApplDoc = (dispatch, token, id, side) => async (dispatch) => {

    dispatch(downloadCompanyApplDocRequest());

    try {
        const { data: { downloadUrl, filename } } = await axios.instance.get(
            `${EndpointsExpress.COMPANY_REQUESTS_V1}/${id}/doc/${side}`
        );

        // Fetch the file blob using native fetch
        const response = await fetch(downloadUrl);
        const blob = await response.blob();

        FileDownload(blob, filename);
        dispatch(downloadCompanyApplDocSuccess());
    } catch (error) {
        if (!error.response) {
            dispatch(failure('Error', error.message));
        } else {
            dispatch(failure(error.response.data.error, error.response.data.message));
        }
    }

//     dispatch(downloadCompanyApplDocRequest())
//         let header = {};
//         header["Authorization"] = token;
//
//         let config = {};
//         config["headers"] = header;
//         config["url"] = `${EndpointsExpress.COMPANY_REQUESTS_V1}/${id}/doc/${side}`;
//         config["responseType"] = "arraybuffer";
//
//         return axios.instance(config)
//             .then(response => {
//                 dispatch(downloadCompanyApplDocSuccess())
//                 const fileNameHeader = "x-suggested-filename";
//                 const suggestedFileName = response.headers[fileNameHeader]
//                 let fn = decodeURIComponent(suggestedFileName);
//
//                 FileDownload(response.data, fn)
//
//             })
//             .catch(error => {
//
//                 if (error.response === undefined) {
//                     dispatch(failure("Error", error.message));
//                 } else {
//                     dispatch(failure(error.response.data.error, error.response.data.message));
//                 }
// //                throw(error);
//             });

}

export const downloadCompanyApplDocSuccess = () => {
    return {
        type: ActionTypes.DOWNLOAD_COMPANY_APPL_DOC_SUCCESS
    }
};


export const deleteCompanyDocRequest = () => {
    return {
        type: ActionTypes.DELETE_COMPANY_DOC_REQUEST
    }
};

export function deleteCompanyDoc(token, id, side) {

    return (dispatch) => {
        dispatch(deleteCompanyDocRequest())
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.COMPANIES_V1}/${id}/doc/${side}`;
        config["method"] = "delete";

        return axios.instance(config)
            .then(response => {

                dispatch(deleteCompanyDocSuccess(response.data.company))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
//                throw(error);
            });
    };

}


export const deleteCompanyDocSuccess = (company) => {
    return {
        type: ActionTypes.DELETE_COMPANY_DOC_SUCCESS,
        company
    }
};




export const failure = (error, message) => {
    return {
        type: ActionTypes.FAILURE,
        error,
        message
    }
};


const signupNewBusinessRequest = () => {
    return {
        type: ActionTypes.CREATE_COMPANY_SELF_REQUEST
    }
};


const signupNewBusinessSuccess = (request) => {
    return {
        type: ActionTypes.CREATE_COMPANY_SELF_SUCCESS,
        request
    }
};

export const signupNewBusiness = (dispatch, info)  => {
    return new Promise(function (resolve, reject) {
        dispatch(signupNewBusinessRequest())
        let header = {}
        //header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.SIGNUPS}/create`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        return axiosNoConfig(config)
            .then(response => {

                dispatch(signupNewBusinessSuccess(response.data.request))
                resolve(response);
            })
            .catch(function (error) {
                    reject(error);
             });
    });
}





const changeCompanyRequest = () => {
    return {
        type: ActionTypes.CHANGE_COMPANY_REQUEST
    }
};

const changeCompanySuccess = (info) => {
    return {
        type: ActionTypes.CHANGE_COMPANY_SUCCESS,
        info
    }
};

export const changeCompany = (token, companyId, info) => {

    return (dispatch) => {

        dispatch(changeCompanyRequest())

        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.COMPANIES_V1}/${companyId}/status`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        return axios.instance(config)
            .then(response => {

                dispatch(changeCompanySuccess(response.data.company))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
            });
    };
};




/*
 *
 * GCP
 *
 */



/*
 *
 * Invoices Bulk
 *
 */

export const setOrderInvoicesSearch = (orderField) => {
    return {
        type: ActionTypes.LOAD_INVOICES_SET_ORDER,
        orderField
    }
};

export const invoicesPageSelected = (page) => {
    return {
        type: ActionTypes.INVOICES_PAGE_SELECTED,
        page
    }
};

export const fetchAllInvoicesRequest = () => {
    return {
        type: ActionTypes.LOAD_ALL_INVOICES_REQUEST
    }
};

// qname, qlabel, qmonth, qgcp,
//     page, pagesize, order, asc
export const fetchAllInvoices = (token, name, label, month, gcp, page, pagesize, order, asc) => {

    return (dispatch) => {
        dispatch(fetchAllInvoicesRequest())
        let header = {};
        //header["Authorization"] = token;
        header["Accept"] = "application/json";
        header["Content-Type"] = "application/json";

        let config = {};
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.INVOICES_ADMIN}/?name=${name}&label=${label}&month=${month}&gcp=${gcp}&page=${page}&pagesize=${pagesize}&order=${order}&asc=${asc}`;

        return axios.instance(config)
            .then(response => {

                // let p = response.data.invoices.reduce((map, obj) => (map[obj.id] = obj, map), {});
                // dispatch(fetchAllInvoicesSuccess(p, response.data.total_amount, response.data.meta))
                dispatch(fetchAllInvoicesSuccess(response.data.invoices, response.data.total_amount, response.data.meta))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
//                throw(error);

            });
    };

}

export const fetchAllInvoicesSuccess = (invoices, total_amount,   meta) => {
    return {
        type: ActionTypes.LOAD_ALL_INVOICES_SUCCESS,
        invoices,
        total_amount,
        meta
    }
};


const createInvoicesBulkRequest = () => {
    return {
        type: ActionTypes.CREATE_INVOICE_BULK_REQUEST
    }
};

const createInvoicesBulkSuccess = (invoices, meta) => {
    return {
        type: ActionTypes.CREATE_INVOICE_BULK_SUCCESS,
        invoices,
        meta
    }
};

export const createInvoicesBulk = (token, info) => {

    return (dispatch) => {
        dispatch(createInvoicesBulkRequest())
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.INVOICES_ADMIN}/create`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);
        return axios.instance(config)
            .then(response => {

                let p = response.data.invoices.reduce((map, obj) => (map[obj.id] = obj, map), {});
                dispatch(createInvoicesBulkSuccess(p, response.data.meta))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
//                throw(error);

            });
    };
};




const removeAllInvoicesBulkRequest = () => {
    return {
        type: ActionTypes.DELETE_ALL_INVOICES_BULK_REQUEST
    }
};

const removeAllInvoicesBulkSuccess = (status) => {
    return {
        type: ActionTypes.DELETE_ALL_INVOICES_BULK_SUCCESS,
        status
    }
};

export const removeAllInvoicesBulk = (token) => {

    return (dispatch) => {

        dispatch(removeAllInvoicesBulkRequest())
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.INVOICES_ADMIN}/removeall`;
        config["method"] = "delete";

        return axios.instance(config)
            .then(response => {

                dispatch(removeAllInvoicesBulkSuccess(response.data.status))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
//                throw(error);

            });
    };
};






/*
 *
 * Company Invoices
 *
 */


const fetchInvoicesRequest = () => {
    return {
        type: ActionTypes.LOAD_INVOICES_REQUEST
    }
};

const fetchInvoicesSuccess = (invoices) => {
    return {
        type: ActionTypes.LOAD_INVOICES_SUCCESS,
        invoices
    }
};

export const fetchInvoices = (token, gcpId) => {

    return (dispatch) => {
        dispatch(fetchInvoicesRequest())
        let header = {};
        header["Authorization"] = token;
        header["Accept"] = "application/json";
        header["Content-Type"] = "application/json";

        let config = {};
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.GCP_ADMIN}/${gcpId}/invoices`;

        return axios.instance(config)
            .then(response => {

                let p = response.data.invoices.reduce((map, obj) => (map[obj.id] = obj, map), {});
                dispatch(fetchInvoicesSuccess(p))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
//                throw(error);

            });
    };

}



export const fetchInvoiceRequest = () => {
    return {
        type: ActionTypes.GET_INVOICE_REQUEST
    }
};

export const fetchInvoice = (token, invoiceId) => {
    return (dispatch) => {
        dispatch(fetchInvoiceRequest())
        let header = {};
        header["Authorization"] = token;
        header["Accept"] = "application/json";
        header["Content-Type"] = "application/json";

        let config = {};
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.INVOICE_ADMIN}/${invoiceId}`;

        return axios.instance(config)
            .then(response => {

                dispatch(fetchInvoiceSuccess(response.data.status, response.data.invoice, response.data.items))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
//                throw(error);

            });
    };
};

export const fetchInvoiceSuccess = (status, invoice, items) => {
    return {
        type: ActionTypes.GET_INVOICE_SUCCESS,
        status,
        invoice,
        items
    }
};


const createInvoiceRequest = () => {
    return {
        type: ActionTypes.CREATE_INVOICE_REQUEST
    }
};

const createInvoiceSuccess = (status, invoice) => {
    return {
        type: ActionTypes.CREATE_INVOICE_SUCCESS,
        status,
        invoice
    }
};

export const createInvoice = (token, gcpId, info) => {

    return (dispatch) => {
        dispatch(createInvoiceRequest())
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.GCP}/${gcpId}/invoice`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        return axios.instance(config)
            .then(response => {

                dispatch(createInvoiceSuccess(response.data.status, response.data.invoice))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
//                throw(error);

            });
    };
};



const removeInvoiceRequest = () => {
    return {
        type: ActionTypes.DELETE_INVOICE_REQUEST
    }
};

const removeInvoiceSuccess = (invoiceId) => {
    return {
        type: ActionTypes.DELETE_INVOICE_SUCCESS,
        invoiceId
    }
};

export const removeInvoice = (token, id) => {

    return (dispatch) => {

        dispatch(removeInvoiceRequest())
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.INVOICE_ADMIN}/${id}`;
        config["method"] = "delete";

        return axios.instance(config)
            .then(response => {
                dispatch(removeInvoiceSuccess(id))
            })
            .catch(error => {
                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
//                throw(error);

            });
    };
};



const printInvoiceRequest = () => {
    return {
        type: ActionTypes.PRINT_INVOICE_REQUEST
    }
};

const printInvoiceSuccess = () => {
    return {
        type: ActionTypes.PRINT_INVOICE_SUCCESS
    }
};

export function printInvoice(token, id) {

    return (dispatch) => {
        dispatch(printInvoiceRequest())
        let header = {};
        // header["Accept"] = "application/json"
        // header["Content-Type"] = "application/json"

        let config = {};
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.INVOICE_ADMIN}/${id}/print`;
        config["responseType"] = "arraybuffer";

        return axios.instance(config)
            .then(response => {
                dispatch(printInvoiceSuccess())
                const fileNameHeader = "x-suggested-filename";
                const suggestedFileName = response.headers[fileNameHeader]
                let fn = decodeURIComponent(suggestedFileName);

                FileDownload(response.data, fn)

            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
//                throw(error);

            });
    };
}


/*
 *
 * Payments
 *
 */
const fetchPaymentsRequest = () => {
    return {
        type: ActionTypes.LOAD_PAYMENTS_REQUEST
    }
};

const fetchPaymentsSuccess = (payments) => {
    return {
        type: ActionTypes.LOAD_PAYMENTS_SUCCESS,
        payments
    }
};

export const fetchPayments = (dispatch, token, gcpId) => {

        dispatch(fetchPaymentsRequest())
        let header = {};
        header["Authorization"] = token;
        header["Accept"] = "application/json";
        header["Content-Type"] = "application/json";

        let config = {};
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.GCP_ADMIN}/${gcpId}/payments`;

        axios.instance(config)
            .then(response => {

                //let p = response.data.payments.reduce((map, obj) => (map[obj.year] = obj, map), {});
                dispatch(fetchPaymentsSuccess(response.data.payments))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
            });


}



const createPaymentRequest = () => {
    return {
        type: ActionTypes.CREATE_PAYMENT_REQUEST
    }
};

const createPaymentSuccess = (payment) => {
    return {
        type: ActionTypes.CREATE_PAYMENT_SUCCESS,
        payment
    }
};

export const createPayment = (dispatch, token, gcpId, info) => {


        dispatch(createPaymentRequest())
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.GCP_ADMIN}/${gcpId}/payment`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        return axios.instance(config)
            .then(response => {

                dispatch(createPaymentSuccess(response.data.payment))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
            });
};



const removePaymentRequest = () => {
    return {
        type: ActionTypes.DELETE_PAYMENT_REQUEST
    }
};

const removePaymentSuccess = (payment) => {
    return {
        type: ActionTypes.DELETE_PAYMENT_SUCCESS,
        payment
    }
};

export const removePayment = (dispatch, token, gcpId, info) => {


        dispatch(removePaymentRequest())
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.GCP_ADMIN}/${gcpId}/payment`;
        config["method"] = "delete";
        config["data"] = JSON.stringify(info);

        axios.instance(config)
            .then(response => {

                dispatch(removePaymentSuccess(response.data.payment))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
//                throw(error);

            });

};


/*
 *
 *  Payment Note
 *
 */

const createPaymentNoteRequest = () => {
    return {
        type: ActionTypes.CREATE_PAYMENT_NOTE_REQUEST
    }
};
const createPaymentNoteSuccess = (note) => {
    return {
        type: ActionTypes.CREATE_PAYMENT_NOTE_SUCCESS,
        note
    }
};

export const createPaymentNote = (dispatch, token, gcpId, info) => {
        dispatch(createPaymentNoteRequest())
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.GCP_ADMIN}/${gcpId}/note`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        axios.instance(config)
            .then(response => {

                dispatch(createPaymentNoteSuccess(response.data.note))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                throw(error);

            });

};



const removePaymentNoteRequest = () => {
    return {
        type: ActionTypes.DELETE_PAYMENT_NOTE_REQUEST
    }
};

const removePaymentNoteSuccess = (note) => {
    return {
        type: ActionTypes.DELETE_PAYMENT_NOTE_SUCCESS,
        note
    }
};

export const removePaymentNote = (dispatch, token, id) => {


        dispatch(removePaymentNoteRequest())
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.GCP_ADMIN}/${id}/note`;
        config["method"] = "delete";

        axios.instance(config)
            .then(response => {

                dispatch(removePaymentNoteSuccess(response.data.note))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                throw(error);
            });

};


/*
 *
 * barcode images
 *
 */





export const uploadBarcodeRequest = () => {
    return {
        type: ActionTypes.UPLOAD_BARCODE_REQUEST
    }
};

export function uploadBarcode(token, id, file, issued) {

    return dispatch => {
        dispatch(uploadBarcodeRequest())
        let url = `${EndpointsExpress.GCP_ADMIN}/${id}/upload`;
        let data = new FormData();
        data.append('file', file)
        data.append('issued', issued)

        let config = {};
        config["Authorization"] = token;
        config['Content-Type'] = 'multipart/form-data';
        return axios.instance.post(url, data, {headers: config})
            .then(function (response) {

                dispatch(uploadBarcodeSuccess())
            })
            .catch(function (error) {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
//                throw(error);
            });
    }
}


export const uploadBarcodeSuccess = () => {
    return {
        type: ActionTypes.UPLOAD_BARCODE_SUCCESS,

    }
};



export const uploadProduct8FileRequest = () => {
    return {
        type: ActionTypes.UPLOAD_PRODUCT8_FILE_REQUEST
    }
};

export function uploadProduct8File(dispatch, token, id, file, issued) {

    return new Promise(function (resolve, reject) {
        dispatch(uploadBarcodeRequest())
        let url = `${EndpointsExpress.PRODUCT8}/${id}/upload`;
        let data = new FormData();
        data.append('file', file)
        data.append('issued', issued)

        let config = {};
        config["Authorization"] = token;
        config['Content-Type'] = 'multipart/form-data';
        axios.instance.post(url, data, {headers: config})
            .then(function (response) {
                dispatch(uploadProduct8FileSuccess(response.data.file));
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            });
    });

}


export const uploadProduct8FileSuccess = (file) => {
    return {
        type: ActionTypes.UPLOAD_PRODUCT8_FILE_SUCCESS,
        file
    }
};




export const removeBarcodeRequest = () => {
    return {
        type: ActionTypes.DELETE_BARCODE_REQUEST
    }
};

export const removeBarcodeSuccess = (barcode) => {
    return {
        type: ActionTypes.DELETE_BARCODE_SUCCESS,
        barcode
    }
};

export const removeBarcode = (dispatch, token, id) => {

    return new Promise(function (resolve, reject) {
        dispatch(removeBarcodeRequest())
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.BARCODES_V1}/${id}`;
        config["method"] = "delete";

        axios.instance(config)
            .then(response => {

                dispatch(removeBarcodeSuccess(response.data.barcode_image))
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
};




/*
 *
 * Product
 *
 */
export const selectProduct = (id) => {
    return {
        type: ActionTypes.SELECT_PRODUCT,
        id
    }
};

export const selectProductHeader = () => {
    return {
        type: ActionTypes.SELECT_PRODUCT_HEADER

    }
};

export const resetProductUpdateStatus = () => {
    return {
        type: ActionTypes.RESET_PRODUCT_UPDATE_STATUS
    }
};

export const fetchProductsRequest = () => {
    return {
        type: ActionTypes.LOAD_PRODUCTS_REQUEST
    }
};

export const fetchProductsSuccess = (products, meta) => {
    return {
        type: ActionTypes.LOAD_PRODUCTS_SUCCESS,
        products,
        meta
    }
};

export const fetchProducts = (dispatch, token, companyId, page, pagesize, order, asc) => {

        dispatch(fetchProductsRequest())
        let header = {};
        header["Authorization"] = token;
        header["Accept"] = "application/json";
        header["Content-Type"] = "application/json";

        let config = {};
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.PRODUCTS_ADMIN}/${companyId}/?page=${page}&pagesize=${pagesize}&order=${order}&asc=${asc}`;

        return axios.instance(config)
            .then(response => {

                dispatch(fetchProductsSuccess(response.data.products, response.data.meta))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
            });

}




export const fetchAllProductsRequest = () => {
    return {
        type: ActionTypes.LOAD_ALL_PRODUCTS_REQUEST
    }
};
export const fetchAllProducts = (token, companyName, name, barcode, barcode_type, main, sub, unit, isEmpty, isHq, created, from, to, page, pagesize, order, asc) => {

    return (dispatch) => {
        dispatch(fetchAllProductsRequest())
        let header = {};
        header["Authorization"] = token;
        header["Accept"] = "application/json";
        header["Content-Type"] = "application/json";

        let config = {};
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.PRODUCTS_ADMIN}/all/?company_name=${companyName}&name=${name}&barcode=${barcode}&barcode_type=${barcode_type}&main=${main}&sub=${sub}&unit=${unit}&empty=${isEmpty}&is_hq=${isHq}&created=${created}&from=${from}&to=${to}&page=${page}&pagesize=${pagesize}&order=${order}&asc=${asc}`;

        return axios.instance(config)
            .then(response => {

                dispatch(fetchAllProductsSuccess(response.data.products, response.data.meta))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
            });
    };
}


export const fetchAllProductsSuccess = (products, meta) => {
    return {
        type: ActionTypes.LOAD_ALL_PRODUCTS_SUCCESS,
        products,
        meta
    }
};



const fetchAllProducts14Request = () => {
    return {
        type: ActionTypes.LOAD_ALL_PRODUCTS14_REQUEST
    }
};
const fetchAllProducts14Success = (products, meta) => {
    return {
        type: ActionTypes.LOAD_ALL_PRODUCTS14_SUCCESS,
        products,
        meta
    }
};

export const fetchAllProducts14 = ( page, pagesize, order, asc) => {

    return (dispatch) => {
        dispatch(fetchAllProducts14Request())
        let header = {};
        header["Accept"] = "application/json";
        header["Content-Type"] = "application/json";

        let config = {};
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.PRODUCTS_ADMIN}/all/?is_14=true&page=${page}&pagesize=${pagesize}&order=${order}&asc=${asc}`;

        return axios.instance(config)
            .then(response => {

                dispatch(fetchAllProducts14Success(response.data.products, response.data.meta))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
            });
    };
}




const fetchEditProductsRequest = () => {
    return {
        type: ActionTypes.LOAD_EDIT_PRODUCTS_REQUEST
    }
};
const fetchEditProductsSuccess = (products, meta) => {
    return {
        type: ActionTypes.LOAD_EDIT_PRODUCTS_SUCCESS,
        products,
        meta
    }
};

export const fetchEditProducts = (dispatch, company_name, page, pagesize, order, asc) => {


        dispatch(fetchEditProductsRequest())
        let header = {};
        header["Accept"] = "application/json";
        header["Content-Type"] = "application/json";

        let config = {};
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.PRODUCTS_ADMIN}/edit/?company_name=${company_name}&page=${page}&pagesize=${pagesize}&order=${order}&asc=${asc}`;

        return axios.instance(config)
            .then(response => {

                dispatch(fetchEditProductsSuccess(response.data.products, response.data.meta))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
            });

}



const fetchVerifyProductsRequest = () => {
    return {
        type: ActionTypes.LOAD_VERIFY_PRODUCTS_REQUEST
    }
};
const fetchVerifyProductsSuccess = (products, meta) => {
    return {
        type: ActionTypes.LOAD_VERIFY_PRODUCTS_SUCCESS,
        products,
        meta
    }
};

export const fetchVerifyProducts = (dispatch, isHq, from, to, page, pagesize, order, asc) => {


    dispatch(fetchVerifyProductsRequest())
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.PRODUCTS_ADMIN}/verify/?is_hq=${isHq}&from=${from}&to=${to}&page=${page}&pagesize=${pagesize}&order=${order}&asc=${asc}`;

    return axios.instance(config)
        .then(response => {

            dispatch(fetchVerifyProductsSuccess(response.data.products, response.data.meta))
        })
        .catch(error => {

            if (error.response === undefined) {
                dispatch(failure("Error", error.message));
            } else {
                dispatch(failure(error.response.data.error, error.response.data.message));
            }
        });

}





export const printAllProductsRequest = () => {
    return {
        type: ActionTypes.PRINT_ALL_PRODUCTS_REQUEST
    }
};
export const printAllProducts = (token, companyName, name, barcode, barcodeType, main, sub, unit, isEmpty, created, from, to) => {

    return (dispatch) => {
        dispatch(printAllProductsRequest())
        let header = {};
        header["Authorization"] = token;
        header["Accept"] = "application/json";
        header["Content-Type"] = "application/json";

        let config = {};
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.PRODUCTS_ADMIN}/print-new/?company_name=${companyName}&name=${name}&barcode=${barcode}&barcode_type=${barcodeType}&main=${main}&sub=${sub}&unit=${unit}&empty=${isEmpty}&created=${created}&from=${from}&to=${to}`;
        config["responseType"] = "arraybuffer";

        return axios.instance(config)
            .then(response => {

                dispatch(printAllProductsSuccess())

                // const url = window.URL.createObjectURL(new Blob([response.data]));
                // const link = document.createElement('a');
                // link.href = url;
                // link.setAttribute('products', `${Date.now()}.xlsx`);
                // document.body.appendChild(link);
                // link.click();

                // const fileNameHeader = "x-suggested-filename";
                // const suggestedFileName = response.headers[fileNameHeader]
                let fn = decodeURIComponent('products-' + Date.now()+'.xlsx'); //suggestedFileName);

                var bytes = new Uint8Array(response.data);

                var blob = new Blob([bytes], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                FileSaver.saveAs(blob, fn)

                // var blob2 = new Blob(["Hello, world!"], {type: "text/plain;charset=utf-8"});
                // FileSaver.saveAs(blob2, "hello world.txt");
            })
            .catch(error => {
                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
            });
    };
}


export const printAllProductsSuccess = () => {
    return {
        type: ActionTypes.PRINT_ALL_PRODUCTS_SUCCESS,
    }
};


export const changeTempProduct = (f, payload) => {
    return {
        type: ActionTypes.TEMP_PRODUCT_CHANGE,
        f,
        payload
    }
};

const fetchProductRequest = () => {
    return {
        type: ActionTypes.GET_PRODUCT_REQUEST
    }
};

const fetchProductSuccess = (status, product, gcps) => {
    return {
        type: ActionTypes.GET_PRODUCT_SUCCESS,
        status,
        product,
        gcps
    }
};


export const fetchProductNew = (dispatch, token, productId) => {

        dispatch(fetchProductRequest())
        let header = {};

        header["Accept"] = "application/json";
        header["Content-Type"] = "application/json";

        let config = {};
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.PRODUCT_ADMIN}/${productId}`;

        return axios.instance(config)
            .then(response => {

                dispatch(fetchProductSuccess(response.data.status, response.data.product, response.data.gcps))
            })
            .catch(error => {

                // if (error.response === undefined) {
                //     dispatch(failure("Error", error.message));
                // } else {
                //     dispatch(failure(error.response.data.error, error.response.data.message));
                // }
                throw(error);

            });

}

// fetch next

const fetchNextProductRequest = () => {
    return {
        type: ActionTypes.GET_PRODUCT_REQUEST
    }
};

const fetchNextProductSuccess = (status, product, gcps) => {
    return {
        type: ActionTypes.GET_PRODUCT_SUCCESS,
        status,
        product,
        gcps
    }
};


export const fetchNextProduct = (dispatch,  next, currProductId) => {

    dispatch(fetchNextProductRequest())
    let header = {};

    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.PRODUCT_ADMIN}/next/${currProductId}&next`;

    return axios.instance(config)
        .then(response => {

            dispatch(fetchNextProductSuccess(response.data.status, response.data.product))
        })
        .catch(error => {

            // if (error.response === undefined) {
            //     dispatch(failure("Error", error.message));
            // } else {
            //     dispatch(failure(error.response.data.error, error.response.data.message));
            // }
            throw(error);

        });

}


/*
PRDUCT8
 */

export const fetchAllProduct8sRequest = () => {
    return {
        type: ActionTypes.LOAD_ALL_PRODUCT8S_REQUEST
    }
};
export const fetchAllProduct8s = (token, name, barcode, page, pagesize, order, asc) => {

    return (dispatch) => {
        dispatch(fetchAllProduct8sRequest())
        let header = {};
        header["Authorization"] = token;
        header["Accept"] = "application/json";
        header["Content-Type"] = "application/json";

        let config = {};
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.PRODUCT8S_ADMIN}/all?name=${name}&barcode=${barcode}&page=${page}&pagesize=${pagesize}&order=${order}&asc=${asc}`;

        return axios.instance(config)
            .then(response => {

                dispatch(fetchAllProduct8sSuccess(response.data.products, response.data.meta))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
            });
    };
}


export const fetchAllProduct8sSuccess = (products, meta) => {
    return {
        type: ActionTypes.LOAD_ALL_PRODUCT8S_SUCCESS,
        products,
        meta
    }
};


const fetchProduct8sRequest = () => {
    return {
        type: ActionTypes.LOAD_COMPANY_PRODUCT8S_REQUEST
    }
};

const fetchProduct8sSuccess = (products, meta, name) => {
    return {
        type: ActionTypes.LOAD_COMPANY_PRODUCT8S_SUCCESS,
        products,
        meta,
        name
    }
};


export const fetchProduct8s = (dispatch, token, companyId, page, pagesize, order, asc) => {

    dispatch(fetchProduct8sRequest())
    let header = {};
    header["Authorization"] = token;
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.PRODUCT8S_ADMIN}/${companyId}/?page=${page}&pagesize=${pagesize}&order=${order}&asc=${asc}`;

    return axios.instance(config)
        .then(response => {

            dispatch(fetchProduct8sSuccess(response.data.products, response.data.meta, response.data.company_name))
        })
        .catch(error => {

            if (error.response === undefined) {
                dispatch(failure("Error", error.message));
            } else {
                dispatch(failure(error.response.data.error, error.response.data.message));
            }
        });
}


export const fetchProduct8Request = () => {
    return {
        type: ActionTypes.GET_PRODUCT8_REQUEST
    }
};


export const fetchProduct8Success = (status, product) => {
    return {
        type: ActionTypes.GET_PRODUCT8_SUCCESS,
        status,
        product
    }
};
export const fetchProduct8 = (dispatch, token, productId) => {

        dispatch(fetchProduct8Request())
        let header = {};
        header["Authorization"] = token;
        header["Accept"] = "application/json";
        header["Content-Type"] = "application/json";

        let config = {};
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.PRODUCT8_ADMIN}/${productId}`;

        return axios.instance(config)
            .then(response => {

                dispatch(fetchProduct8Success(response.data.status, response.data.product))
            })
            .catch(error => {

                // if (error.response === undefined) {
                //     dispatch(failure("Error", error.message));
                // } else {
                //     dispatch(failure(error.response.data.error, error.response.data.message));
                // }
                throw(error);

            });
}


export const createProduct8Request = () => {
    return {
        type: ActionTypes.CREATE_PRODUCT8_REQUEST
    }
};

export const createProduct8 = (token, info) => (dispatch) =>

    new Promise(function (resolve, reject) {

        dispatch(createProduct8Request())
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.PRODUCT8S_ADMIN}`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        return axios.instance(config)
            .then(response => {

                dispatch(createProduct8Success(response.data.product, response.data.status))
                resolve(response);
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));

                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                reject(error);
//                throw(error);

            });
    });


export const createProduct8Success = (product, status) => {
    return {
        type: ActionTypes.CREATE_PRODUCT8_SUCCESS,
        product,
        status
    }
};


export const updateProduct8Request = () => {
    return {
        type: ActionTypes.UPDATE_PRODUCT8_REQUEST
    }
};

export const updateProduct8 = (token, id, info) => (dispatch) =>

    new Promise(function (resolve, reject) {

        dispatch(updateProduct8Request())
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.PRODUCT8_ADMIN}/${id}`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        return axios.instance(config)
            .then(response => {

                dispatch(updateProduct8Success(id))
                resolve(response);
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                reject(error);

            });
    });


export const updateProduct8Success = (id) => {
    return {
        type: ActionTypes.UPDATE_PRODUCT8_SUCCESS,
        id
    }
};

/*
*
*
* ---------------------------------
*
 */

export const createProductRequest = () => {
    return {
        type: ActionTypes.CREATE_PRODUCT_REQUEST
    }
};

export const createProductSuccess = (product, status) => {
    return {
        type: ActionTypes.CREATE_PRODUCT_SUCCESS,
        product,
        status
    }
};

export const createProduct = (dispatch,  companyId, info) => {

    return new Promise(function (resolve, reject) {

        dispatch(createProductRequest())
        let header = {}

        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.COMPANY}/${companyId}/products`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        axios.instance(config)
            .then(response => {

                dispatch(createProductSuccess(response.data.product, response.data.status))
                resolve(response);
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));

                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                reject(error);
//                throw(error);

            });
    });
}





const updateProductRequest = () => {
    return {
        type: ActionTypes.UPDATE_PRODUCT_REQUEST
    }
};

const updateProductSuccess = (product, status) => {
    return {
        type: ActionTypes.UPDATE_PRODUCT_SUCCESS,
        product,
        status
    }
};

export const updateProduct = (dispatch,  id, info) => {

    return new Promise(function (resolve, reject) {

        dispatch(updateProductRequest())
        let header = {}
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.PRODUCT_ADMIN}/${id}`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        axios.instance(config)
            .then(response => {

                dispatch(updateProductSuccess(response.data.product, response.data.status))
                resolve(response);
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                reject(error);

            });
    });
}




const verifyProductRequest = () => {
    return {
        type: ActionTypes.VERIFY_PRODUCT_REQUEST
    }
};

const verifyProductSuccess = ( product, status) => {
    return {
        type: ActionTypes.VERIFY_PRODUCT_SUCCESS,
        product,
        status
    }
};

export const verifyProduct = (dispatch,  id, info) => {

    return new Promise(function (resolve, reject) {

        dispatch(verifyProductRequest())
        let header = {}
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.PRODUCT_ADMIN}/${id}/verify`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        axios.instance(config)
            .then(response => {

                dispatch(verifyProductSuccess( response.data.product, response.data.status))
                resolve(response);
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                reject(error);

            });
    });
}


export const updateProductsCatRequest = () => {
    return {
        type: ActionTypes.UPDATE_PRODUCTS_CAT_REQUEST
    }
};

export const updateProductsCat = (token, info) => (dispatch) =>
    new Promise(function (resolve, reject) {


        dispatch(updateProductsCatRequest())
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.PRODUCTS_V1}/updatecl`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        return axios.instance(config)
            .then(response => {

                dispatch(updateProductsCatSuccess(response.data.status, info.main, info.sub, info.unit))
                resolve(response);
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                reject(error)
//                throw(error);

            });

    });

export const updateProductsCatSuccess = (status, main, sub, unit) => {
    return {
        type: ActionTypes.UPDATE_PRODUCTS_CAT_SUCCESS,
        status,
        main,
        sub,
        unit
    }
};


export const initTempProduct = () => {
    return {
        type: ActionTypes.INIT_TEMP_PRODUCT,
        product:{}
    }
};

export const removeProductRequest = () => {
    return {
        type: ActionTypes.DELETE_PRODUCT_REQUEST
    }
};

export const removeProductSuccess = (id) => {
    return {
        type: ActionTypes.DELETE_PRODUCT_SUCCESS,
        id
    }
};

export const removeProduct = (dispatch, token, id) => {

    return new Promise(function (resolve, reject) {

        dispatch(removeProductRequest())
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.PRODUCT_ADMIN}/${id}`;
        config["method"] = "delete";

        axios.instance(config)
            .then(response => {

                dispatch(removeProductSuccess(id))
                resolve(response);
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                reject(error)
//                throw(error);

            });
    });
}



const updateProductMultiRequest = () => {
    return {
        type: ActionTypes.UPDATE_PRODUCT_MULTI_REQUEST
    }
};

const updateProductMultiSuccess = () => {
    return {
        type: ActionTypes.UPDATE_PRODUCT_MULTI_SUCCESS
    }
};

export const updateProductMulti = (dispatch, arr) => {

    return new Promise(function (resolve, reject) {
        dispatch(updateProductMultiRequest())

        let header = {}
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.PRODUCTS_ADMIN}/multi_update`;
        config["method"] = "post";
        config["data"] = JSON.stringify(arr);

        axios.instance(config)
            .then(response => {

                dispatch(updateProductMultiSuccess())
                resolve(response)
            })
            .catch(error => {
                reject(error);
            });
    });
};



export const uploadProductImageRequest = () => {
    return {
        type: ActionTypes.UPLOAD_PRODUCT_IMAGE_REQUEST
    }
};
export const uploadProductImageSuccess = (product, side) => {
    return {
        type: ActionTypes.UPLOAD_PRODUCT_IMAGE_SUCCESS,
        product,
        side
    }
};

export function uploadProductImage(dispatch, token, id, file, side) {


        dispatch(uploadProductImageRequest())
        let url = `${EndpointsExpress.PRODUCTS_V1}/${id}/upload`;
        let data = new FormData();
        data.append('file', file)
        data.append('side', side)

        let config = {};
        config["Authorization"] = token;
        config['Content-Type'] = 'multipart/form-data';
        return axios.instance.post(url, data, {headers: config})
            .then(function (response) {

                dispatch(uploadProductImageSuccess(response.data.product, side))
                //console.log(util.inspect(response, false, null));
            })
            .catch(function (error) {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
//                throw(error);

            });

}




export const fetchProductImageRequest = () => {
    return {
        type: ActionTypes.GET_PRODUCT_IMAGE_REQUEST
    }
};


export const fetchProductImageSuccess = (data, id, side) => {
    return {
        type: ActionTypes.GET_PRODUCT_IMAGE_SUCCESS,
        data, id, side
    }
};

export function fetchProductImage(dispatch, token, id, side) {

        dispatch(fetchProductImageRequest())
        let header = {};
        header["Authorization"] = token;

        let config = {};
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.PRODUCT}/${id}/image/${side}`;
        config["responseType"] = "arraybuffer";

        return axios.instance(config)
            .then(response => {

                let prefix = "data:" + response.headers["content-type"] + ";base64,";
                let img = Buffer.from(response.data, "binary").toString("base64");
                let dataUri = prefix + img;
                dispatch(fetchProductImageSuccess(dataUri, id, side))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
            });


}



const fetchProductImageApplRequest = () => {
    return {
        type: ActionTypes.GET_PRODUCT_IMAGE_APPL_REQUEST
    }
};


const fetchProductImageApplSuccess = (data, id) => {
    return {
        type: ActionTypes.GET_PRODUCT_IMAGE_APPL_SUCCESS,
        data, id
    }
};

export function fetchProductImageAppl(dispatch, id) {

    dispatch(fetchProductImageApplRequest())
    let header = {};

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.PRODUCT_IMAGE}/${id}/image`;
    config["responseType"] = "arraybuffer";

    return axios.instance(config)
        .then(response => {

            let prefix = "data:" + response.headers["content-type"] + ";base64,";
            let img = Buffer.from(response.data, "binary").toString("base64");
            let dataUri = prefix + img;
            dispatch(fetchProductImageApplSuccess(dataUri, id))
        })
        .catch(error => {

            if (error.response === undefined) {
                dispatch(failure("Error", error.message));
            } else {
                dispatch(failure(error.response.data.error, error.response.data.message));
            }
        });


}


export const deleteProductImageRequest = () => {
    return {
        type: ActionTypes.DELETE_PRODUCT_IMAGE_REQUEST
    }
};

export function deleteProductImage(dispatch, token, id, side) {

        dispatch(deleteProductImageRequest())
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.PRODUCTS_V1}/${id}/image/${side}`;
        config["method"] = "delete";

        return axios.instance(config)
            .then(response => {

                dispatch(deleteProductImageSuccess(response.data.product, side))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
//                throw(error);

            });
}

export const deleteProductImageSuccess = (product, side) => {
    return {
        type: ActionTypes.DELETE_PRODUCT_IMAGE_SUCCESS,
        product,
        side
    }
};


/*
 *
 * Note
 *
 */




export const menuClicked = (menu, title) => {
    return {
        type: ActionTypes.SIDEMENU_CLICKED,
        menu,
        title
    }
};

export const menuClickedTitle = (title) => {
    return {
        type: ActionTypes.SIDEMENU_COMPANY_CLICKED,
        title
    }
};

export const detailTapped = () => {
    return {
        type: ActionTypes.DETAIL_TAPPED
    }
};

export const contactsTapped = () => {
    return {
        type: ActionTypes.CONTACTS_TAPPED
    }
};

export const gcpsTapped = () => {
    return {
        type: ActionTypes.GCPS_TAPPED
    }
};

export const productsTapped = () => {
    return {
        type: ActionTypes.PRODUCTS_TAPPED
    }
};


export const mainCatInputTapped = () => {
    return {
        type: ActionTypes.MAIN_CAT_INPUT_TAPPED
    }
};

export const subCatInputTapped = () => {
    return {
        type: ActionTypes.SUB_CAT_INPUT_TAPPED
    }
};
export const unitCatInputTapped = () => {
    return {
        type: ActionTypes.UNIT_CAT_INPUT_TAPPED
    }
};

export const resetCatInputTapped = () => {
    return {
        type: ActionTypes.RESET_CAT_INPUT_TAPPED
    }
};


/*
 *
 * SECTOR
 *
 *
 */


export const fetchSectorsRequest = () => {
    return {
        type: ActionTypes.LOAD_SECTORS_REQUEST
    }
};
export const fetchSectors = (token, page, pagesize) => {

    return (dispatch) => {
        dispatch(fetchSectorsRequest())
        let header = {};
        header["Authorization"] = token;
        header["Accept"] = "application/json";
        header["Content-Type"] = "application/json";

        let config = {};
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.SECTORS}/?page=${page}&pagesize=${pagesize}`;

        return axios.instance(config)
            .then(response => {

                dispatch(fetchSectorsSuccess(response.data.items, response.data.meta))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                throw(error);

            });
    };
}


export const fetchSectorsSuccess = (sectors, meta) => {
    return {
        type: ActionTypes.LOAD_SECTORS_SUCCESS,
        sectors,
        meta
    }
};


const fetchSectorsAllRequest = () => {
    return {
        type: ActionTypes.LOAD_SECTORS_ALL_REQUEST
    }
};

const fetchSectorsAllSuccess = (sectors, meta) => {
    return {
        type: ActionTypes.LOAD_SECTORS_ALL_SUCCESS,
        sectors,
        meta
    }
};

export const fetchSectorsAll = (token) => {

    return (dispatch) => {
        dispatch(fetchSectorsAllRequest())
        let header = {};
        header["Authorization"] = token;
        header["Accept"] = "application/json";
        header["Content-Type"] = "application/json";

        let config = {};
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.SECTORS}/all`;

        return axios.instance(config)
            .then(response => {

                dispatch(fetchSectorsAllSuccess(response.data.items, {
                    totalPages: 0,
                    totalElements: 0,
                    page: 0,
                    size: 0
                }))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                throw(error);

            });
    };
}


export const setInquiriesCount = (count) => {
    return {
        type: ActionTypes.INQUIRIES_COUNT,
        count
    }
};

export const fetchInquiriesCount = async (dispatch) => {
    try {
        const response = await axios.instance({
            method: 'GET',
            url: `${EndpointsExpress.INQUIRIES}/count?processed=true`,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        });
        dispatch(setInquiriesCount(response.data.count))
    } catch (error) {

    }
}

export const fetchSectorsAllNoAuthRequest = () => {
    return {
        type: ActionTypes.LOAD_SECTORS_ALL_NO_AUTH_REQUEST
    }
};

export const fetchSectorsAllNoAuthSuccess = (sectors) => {
    return {
        type: ActionTypes.LOAD_SECTORS_ALL_NO_AUTH_SUCCESS,
        sectors

    }
};



const fetchSectorsAllNoAuth2Request = () => {
    return {
        type: ActionTypes.LOAD_SECTORS_ALL_NO_AUTH_2_REQUEST
    }
};

const fetchSectorsAllNoAuth2Success = (sectors) => {
    return {
        type: ActionTypes.LOAD_SECTORS_ALL_NO_AUTH_2_SUCCESS,
        sectors

    }
};

export const fetchSectorsAllNoAuth2 = (dispatch) => {

        dispatch(fetchSectorsAllNoAuth2Request())
        let header = {};
        header["Accept"] = "application/json";
        header["Content-Type"] = "application/json";

        let config = {};
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.SECTORS_V1}`;

        return axiosNoConfig(config)
            .then(response => {

                dispatch(fetchSectorsAllNoAuth2Success(response.data.items))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                throw(error);

            });

}



export const createSectorRequest = () => {
    return {
        type: ActionTypes.CREATE_SECTOR_REQUEST
    }
};

export const createSector = (token, info) => {

    return (dispatch) => {
        dispatch(createSectorRequest())
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.SECTORS}`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        return axios.instance(config)
            .then(response => {

                dispatch(createSectorSuccess(response.data.sector))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                throw(error);

            });
    };
};

export const createSectorSuccess = (sector) => {
    return {
        type: ActionTypes.CREATE_SECTOR_SUCCESS,
        sector
    }
};

export const updateSectorRequest = () => {
    return {
        type: ActionTypes.UPDATE_SECTOR_REQUEST
    }
};


export const updateSector = (token, sectorId, info) => {

    return (dispatch) => {

        dispatch(updateSectorRequest())

        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.SECTOR}/${sectorId}`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        return axios.instance(config)
            .then(response => {

                dispatch(updateSectorSuccess(response.data.sector))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                throw(error);

            });
    };
};

export const updateSectorSuccess = (sector) => {
    return {
        type: ActionTypes.UPDATE_SECTOR_SUCCESS,
        sector
    }
};


export const removeSectorRequest = () => {
    return {
        type: ActionTypes.DELETE_SECTOR_REQUEST
    }
};

export const removeSector = (token, sectorId) => {

    return (dispatch) => {
        dispatch(removeSectorRequest())
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.SECTOR}/${sectorId}`;
        config["method"] = "delete";

        return axios.instance(config)
            .then(response => {

                dispatch(removeSectorSuccess(response.data.sectors, response.data.meta))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                throw(error);

            });
    };
};

export const removeSectorSuccess = (sectors, meta) => {
    return {
        type: ActionTypes.DELETE_SECTOR_SUCCESS,
        sectors,
        meta

    }
};


/*
 *
 * PRODUCT CLASSIF
 *
 *
 */


export const fetchClassifsRequest = (type) => {
    if (type === "main") {
        return {
            type: ActionTypes.LOAD_CLASSIF_MAIN_REQUEST
        }
    } else if (type === "sub") {
        return {
            type: ActionTypes.LOAD_CLASSIF_SUB_REQUEST

        }
    } else if (type === "unit") {
        return {
            type: ActionTypes.LOAD_CLASSIF_UNIT_REQUEST
        }
    }
};
export const fetchClassifsSuccess = (classifs, type, id) => {
    if (type === "main") {
        return {
            type: ActionTypes.LOAD_CLASSIF_MAIN_SUCCESS,
            classifs
        }
    } else if (type === "sub") {
        return {
            type: ActionTypes.LOAD_CLASSIF_SUB_SUCCESS,
            classifs,
            parent: id
        }
    } else if (type === "unit") {
        return {
            type: ActionTypes.LOAD_CLASSIF_UNIT_SUCCESS,
            classifs,
            parent: id
        }
    }
};

export const fetchClassifs = (token, type, id) => {

    return (dispatch) => {
        dispatch(fetchClassifsRequest(type))
        let header = {};
        header["Authorization"] = token;
        header["Accept"] = "application/json";
        header["Content-Type"] = "application/json";

        let config = {};
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.CLASSIF}/${type}/${id}`;

        return axios.instance(config)
            .then(response => {

                let items = response.data.items;
                let  i = 0;
                items.map(p=>{
                    p.order = i;
                    i++;
                })
                let p = items.reduce((map, obj) => (map[obj.id] = obj, map), {});
                dispatch(fetchClassifsSuccess(p, type, id))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                throw(error);

            });
    };
}



const fetchClassifsNouvRequest = (type) => {
    if (type === "main") {
        return {
            type: ActionTypes.LOAD_CLASSIF_NOUV_MAIN_REQUEST
        }
    } else if (type === "sub") {
        return {
            type: ActionTypes.LOAD_CLASSIF_NOUV_SUB_REQUEST

        }
    } else if (type === "unit") {
        return {
            type: ActionTypes.LOAD_CLASSIF_NOUV_UNIT_REQUEST
        }
    }
};

const fetchClassifsNouvSuccess = (classifs, type, id) => {
    if (type === "main") {
        return {
            type: ActionTypes.LOAD_CLASSIF_NOUV_MAIN_SUCCESS,
            classifs
        }
    } else if (type === "sub") {
        return {
            type: ActionTypes.LOAD_CLASSIF_NOUV_SUB_SUCCESS,
            classifs,
            parent: id
        }
    } else if (type === "unit") {
        return {
            type: ActionTypes.LOAD_CLASSIF_NOUV_UNIT_SUCCESS,
            classifs,
            parent: id
        }
    }
};

export const fetchClassifsNouv = (dispatch, type, id) => {

        dispatch(fetchClassifsNouvRequest(type))
        let header = {};

        header["Accept"] = "application/json";
        header["Content-Type"] = "application/json";

        let config = {};
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.CLASSIF}/${type}/${id}`;

        return axios.instance(config)
            .then(response => {

                dispatch(fetchClassifsNouvSuccess(response.data.items, type, id))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                throw(error);

            });
}


const printClassifsRequest = () => {
        return {
            type: ActionTypes.PRINT_CLASSIFS_REQUEST
        }
};


const printClassifsSuccess = () => {
        return {
            type: ActionTypes.PRINT_CLASSIFS_SUCCESS,
        }
};


export const printClassifs = () => {

    return (dispatch) => {
        dispatch(printClassifsRequest())
        let header = {};

        header["Accept"] = "application/json";
        header["Content-Type"] = "application/json";

        let config = {};
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.CLASSIF}/print`;
        config["responseType"] = "arraybuffer";

        return axios.instance(config)
            .then(response => {

                dispatch(printClassifsSuccess())

                let fn = decodeURIComponent('product-classifs-' + Date.now()+'.xlsx'); //suggestedFileName);

                var bytes = new Uint8Array(response.data);

                var blob = new Blob([bytes], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                FileSaver.saveAs(blob, fn)
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                throw(error);

            });
    };
}


export const subClassifsClicked = (main) => {

};


export const createClassifRequest = (type) => {
    if (type === "main") {
        return {
            type: ActionTypes.CREATE_CLASSIF_MAIN_REQUEST
        }
    } else if (type === "sub") {
        return {
            type: ActionTypes.CREATE_CLASSIF_SUB_REQUEST

        }
    } else if (type === "unit") {
        return {
            type: ActionTypes.CREATE_CLASSIF_UNIT_REQUEST
        }
    }
};

export const createClassif = (token, type, id, info) => {

    return (dispatch) => {
        dispatch(createClassifRequest(type))
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.CLASSIF}/${type}/${id}`;
        config["method"] = "put";
        config["data"] = JSON.stringify(info);

        return axios.instance(config)
            .then(response => {

                dispatch(createClassifSuccess(response.data.classif, type))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                throw(error);

            });
    };
};

export const createClassifSuccess = (classif, type) => {
    if (type === "main") {
        return {
            type: ActionTypes.CREATE_CLASSIF_MAIN_SUCCESS,
            classif
        }
    } else if (type === "sub") {
        return {
            type: ActionTypes.CREATE_CLASSIF_SUB_SUCCESS,
            classif
        }
    } else if (type === "unit") {
        return {
            type: ActionTypes.CREATE_CLASSIF_UNIT_SUCCESS,
            classif
        }
    }
};


export const updateClassifRequest = (type) => {
    if (type === "main") {
        return {
            type: ActionTypes.UPDATE_CLASSIF_MAIN_REQUEST
        }
    } else if (type === "sub") {
        return {
            type: ActionTypes.UPDATE_CLASSIF_SUB_REQUEST

        }
    } else if (type === "unit") {
        return {
            type: ActionTypes.UPDATE_CLASSIF_UNIT_REQUEST
        }
    }
};


export const updateClassif = (token, type, id, info) => {

    return (dispatch) => {

        dispatch(updateClassifRequest(type))

        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.CLASSIF}/${type}/${id}`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        return axios.instance(config)
            .then(response => {

                dispatch(updateClassifSuccess(response.data.classif, type))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                throw(error);

            });
    };
};

export const updateClassifSuccess = (classif, type) => {
    if (type === "main") {
        return {
            type: ActionTypes.UPDATE_CLASSIF_MAIN_SUCCESS,
            classif
        }
    } else if (type === "sub") {
        return {
            type: ActionTypes.UPDATE_CLASSIF_SUB_SUCCESS,
            classif
        }
    } else if (type === "unit") {
        return {
            type: ActionTypes.UPDATE_CLASSIF_UNIT_SUCCESS,
            classif
        }
    }
};


export const removeClassifRequest = () => {
    return {
        type: ActionTypes.DELETE_CLASSIF_MAIN_REQUEST
    }
};

export const removeClassif = (token, type, id) => {

    return (dispatch) => {
        dispatch(removeClassifRequest())
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.CLASSIF}/${type}/${id}`;
        config["method"] = "delete";

        return axios.instance(config)
            .then(response => {

                dispatch(removeClassifSuccess(response.data.items))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                throw(error);

            });
    };
};

export const removeClassifSuccess = (classifs) => {
    return {
        type: ActionTypes.DELETE_CLASSIF_MAIN_SUCCESS,
        classifs
    }
};


/*

 PACKAGING UNITS
 */


const fetchPackagingUnitsRequest = () => {
    return {
        type: ActionTypes.LOAD_PACKAGING_UNITS_REQUEST
    }
};

const fetchPackagingUnitsSuccess = (units) => {
    return {
        type: ActionTypes.LOAD_PACKAGING_UNITS_SUCCESS,
        units
    }
};

export const fetchPackagingUnits = (dispatch) => {


        dispatch(fetchPackagingUnitsRequest())
        let header = {};

        header["Accept"] = "application/json";
        header["Content-Type"] = "application/json";

        let config = {};
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.PACKAGING_UNITS}`;

        return axios.instance(config)
            .then(response => {
                dispatch(fetchPackagingUnitsSuccess(response.data.units))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                throw(error);

            });

}



/*

 FEE
 */


export const fetchFeesRequest = () => {
    return {
        type: ActionTypes.LOAD_FEES_REQUEST
    }
};


export const fetchFees = (token) => {

    return (dispatch) => {
        dispatch(fetchFeesRequest())
        let header = {};
        header["Authorization"] = token;
        header["Accept"] = "application/json";
        header["Content-Type"] = "application/json";

        let config = {};
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.FEES}`;

        return axios.instance(config)
            .then(response => {

                let p = response.data.fees.reduce((map, obj) => (map[obj.id] = obj, map), {});
                dispatch(fetchFeesSuccess(p))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                throw(error);

            });
    };
}


export const fetchFeesSuccess = (fees) => {
    return {
        type: ActionTypes.LOAD_FEES_SUCCESS,
        fees
    }
};


export const updateFeeRequest = () => {
    return {
        type: ActionTypes.UPDATE_FEE_REQUEST
    }
};


export const updateFee = (token, info) => {

    return (dispatch) => {

        dispatch(updateFeeRequest())

        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.FEES}`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        return axios.instance(config)
            .then(response => {

                dispatch(updateFeeSuccess(response.data.fee))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                throw(error);

            });
    };
};

export const updateFeeSuccess = (fee) => {
    return {
        type: ActionTypes.UPDATE_FEE_SUCCESS,
        fee
    }
};


/*
 *
 * Company Applications
 *
 */


const fetchCompanyApplicationsRequest = () => {
    return {
        type: ActionTypes.LOAD_COMPANY_APPLICATIONS_REQUEST
    }
};

const fetchCompanyApplicationsSuccess = (companies, meta) => {
    return {
        type: ActionTypes.LOAD_COMPANY_APPLICATIONS_SUCCESS,
        companies,
        meta
    }
};

export const fetchCompanyApplications = (dispatch, token, page, pagesize) => {

    dispatch(fetchCompanyApplicationsRequest())
    let header = {};
    header["Authorization"] = token;
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.COMPANY_REQUESTS}/?page=${page}&pagesize=${pagesize}`;

    return axios.instance(config)
        .then(response => {

            //let p = response.data.requests.reduce((map, obj) => (map[obj.id] = obj, map), {});
            dispatch(fetchCompanyApplicationsSuccess(response.data.requests, response.data.meta))
        })
        .catch(error => {

            // if (error.response === undefined) {
            //     dispatch(failure("Error", error.message));
            // } else {
            //     dispatch(failure(error.response.data.error, error.response.data.message));
            // }
            throw(error);

        });
}


export const fetchCompanyApplicationRequest = () => {
    return {
        type: ActionTypes.GET_COMPANY_APPLICATION_REQUEST
    }
};

export const fetchCompanyApplicationSuccess = (status, request) => {
    return {
        type: ActionTypes.GET_COMPANY_APPLICATION_SUCCESS,
        status,
        request
    }
};

export const fetchCompanyApplication = (dispatch, token, requestId) => {

        dispatch(fetchCompanyApplicationRequest())
        let header = {};
        header["Authorization"] = token;
        header["Accept"] = "application/json";
        header["Content-Type"] = "application/json";

        let config = {};
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.COMPANY_REQUEST}/${requestId}`;

        return axios.instance(config)
            .then(response => {

                dispatch(fetchCompanyApplicationSuccess(response.data.status, response.data.request))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                throw(error);

            });

}




export const activateCompanyApplicationRequest = () => {
    return {
        type: ActionTypes.ACTIVATE_COMPANY_APPLICATION_REQUEST
    }
};

export const activateCompanyApplication = (dispatch, token, id, info) => {

    return new Promise(function (resolve, reject) {
        dispatch(activateCompanyApplicationRequest())
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.COMPANY_REQUEST}/${id}/activate`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        axios.instance(config)
            .then(response => {

                dispatch(activateCompanyApplicationSuccess(response.data.request, response.data.status))
                resolve(response);
            })
            .catch(error => {
                reject(error);
                // if (error.response === undefined) {
                //     dispatch(failure("Error", error.message));
                // } else {
                //     dispatch(failure(error.response.data.error, error.response.data.message));
                // }
                // throw(error);
            });
    });
};

export const activateCompanyApplicationSuccess = (request, status) => {
    return {
        type: ActionTypes.ACTIVATE_COMPANY_APPLICATION_SUCCESS,
        request,
        status
    }
};




const removeCompanyApplicationRequest = () => {
    return {
        type: ActionTypes.DELETE_COMPANY_APPLICATION_REQUEST
    }
};

const removeCompanyApplicationSuccess = (id) => {
    return {
        type: ActionTypes.DELETE_COMPANY_APPLICATION_SUCCESS,
        id
    }
};

export const removeCompanyApplication = (dispatch, token, id) => {

    return new Promise(function (resolve, reject) {
        dispatch(removeCompanyApplicationRequest())
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.COMPANY_REQUEST}/${id}`;
        config["method"] = "delete";

        axios.instance(config)
            .then(response => {

                dispatch(removeCompanyApplicationSuccess(id)); //response.data.request, response.data.status))
                resolve(response);
            })
            .catch(error => {
                reject(error)
                // if (error.response === undefined) {
                //     dispatch(failure("Error", error.message));
                // } else {
                //     dispatch(failure(error.response.data.error, error.response.data.message));
                // }
                // throw(error);

            });
    });
};



/*
 *
 * Product Application
 *
 */


const fetchProductApplicationRequest = () => {
    return {
        type: ActionTypes.GET_PRODUCT_APPLICATION_REQUEST
    }
};

const fetchProductApplicationSuccess = (status, request, gcps) => {
    return {
        type: ActionTypes.GET_PRODUCT_APPLICATION_SUCCESS,
        status,
        request,
        gcps
    }
};

export const fetchProductApplication = (dispatch, requestId) => {

        dispatch(fetchProductApplicationRequest())
        let header = {};

        header["Accept"] = "application/json";
        header["Content-Type"] = "application/json";

        let config = {};
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.PRODUCT_REQUEST}/${requestId}`;

        return axios.instance(config)
            .then(response => {

                dispatch(fetchProductApplicationSuccess(response.data.status, response.data.request, response.data.gcps))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                throw(error);

            });

}




const activateProductApplicationRequest = () => {
    return {
        type: ActionTypes.ACTIVATE_PRODUCT_APPLICATION_REQUEST
    }
};


const activateProductApplicationSuccess = (request, status) => {
    return {
        type: ActionTypes.ACTIVATE_PRODUCT_APPLICATION_SUCCESS,
        request,
        status
    }
};

export const activateProductApplication = (dispatch, id, info) =>

    new Promise(function (resolve, reject) {

        dispatch(activateProductApplicationRequest())
        let header = {}
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.PRODUCT_REQUEST}/${id}/activate`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        axios.instance(config)
            .then(response => {

                dispatch(activateProductApplicationSuccess(response.data.request, response.data.status));
                resolve(response);
            })
            .catch(error => {
                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                reject(error)
            });
    });


const denyProductApplicationRequest = () => {
    return {
        type: ActionTypes.DENY_PRODUCT_APPLICATION_REQUEST
    }
};

const denyProductApplicationSuccess = (request, status) => {
    return {
        type: ActionTypes.DENY_PRODUCT_APPLICATION_SUCCESS,
        request,
        status
    }
};

export const denyProductApplication = (token, id, info) => (dispatch) =>

    new Promise(function (resolve, reject) {

        dispatch(denyProductApplicationRequest())
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.PRODUCT_REQUEST}/${id}/deny`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        axios.instance(config)
            .then(response => {

                dispatch(denyProductApplicationSuccess(response.data.request, response.data.status))
                resolve(response);
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                reject(error)
            });
    });



const activateProductImageApplicationRequest = () => {
    return {
        type: ActionTypes.ACTIVATE_PRODUCT_IMAGE_APPLICATION_REQUEST
    }
};


const activateProductImageApplicationSuccess = () => {
    return {
        type: ActionTypes.ACTIVATE_PRODUCT_IMAGE_APPLICATION_SUCCESS
    }
};

export const activateProductImageApplication = (dispatch, id, info) =>

    new Promise(function (resolve, reject) {

        dispatch(activateProductImageApplicationRequest())
        let header = {}
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.PRODUCT_IMAGE_ADMIN}/${id}/activate`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        axios.instance(config)
            .then(response => {

                dispatch(activateProductImageApplicationSuccess());
                resolve(response);
            })
            .catch(error => {
                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                reject(error)
            });
    });



const denyProductImageApplicationRequest = () => {
    return {
        type: ActionTypes.DENY_PRODUCT_IMAGE__APPLICATION_REQUEST
    }
};

const denyProductImageApplicationSuccess = (request, status) => {
    return {
        type: ActionTypes.DENY_PRODUCT_IMAGE_APPLICATION_SUCCESS,
        request,
        status
    }
};

export const denyProductImageApplication = (token, id, info) => (dispatch) =>

    new Promise(function (resolve, reject) {

        dispatch(denyProductImageApplicationRequest())
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.PRODUCT_IMAGE_ADMIN}/${id}/deny`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        axios.instance(config)
            .then(response => {

                dispatch(denyProductImageApplicationSuccess(response.data.request, response.data.status))
                resolve(response);
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                reject(error)
            });
    });


export const removeProductApplicationRequest = () => {
    return {
        type: ActionTypes.DELETE_PRODUCT_APPLICATION_REQUEST
    }
};
export const removeProductApplication = (token, id) => {

    return (dispatch) => {
        dispatch(removeProductApplicationRequest())
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.PRODUCT_REQUEST}/${id}`;
        config["method"] = "delete";

        return axios.instance(config)
            .then(response => {

                dispatch(removeProductApplicationSuccess(response.data.request, response.data.status))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                throw(error);

            });
    };
};

export const removeProductApplicationSuccess = (request, status) => {
    return {
        type: ActionTypes.DELETE_PRODUCT_APPLICATION_SUCCESS,
        request,
        status
    }
};


export const resetErrors = () => {
    return {
        type: ActionTypes.RESET_ERRORS
    }
};


export const createAdminUserRequest = () => {
    return {
        type: ActionTypes.CREATE_ADMIN_USER_REQUEST
    }
};

export const createAdminUser = (token, info) => {

    return (dispatch) => {
        dispatch(createAdminUserRequest())
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.USERS}/create`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        return axios.instance(config)
            .then(response => {

                dispatch(createAdminUserSuccess(response.data.user))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                throw(error);

            });
    };
};

export const createAdminUserSuccess = (user) => {
    return {
        type: ActionTypes.CREATE_ADMIN_USER_SUCCESS,
        user
    }
};


export const changeAdminUserPasswordRequest = () => {
    return {
        type: ActionTypes.UPDATE_ADMIN_USER_PASSWORD_REQUEST
    }
};

export const changeAdminUserPassword = (token, userId, info) => {

    return (dispatch) => {
        dispatch(changeAdminUserPasswordRequest())
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.USERS}/${userId}/pwd`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        return axios.instance(config)
            .then(response => {

                dispatch(changeAdminUserPasswordSuccess(response.data.user))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
                throw(error);

            });
    };
};

export const changeAdminUserPasswordSuccess = (user) => {
    return {
        type: ActionTypes.UPDATE_ADMIN_USER_PASSWORD_SUCCESS,
        user
    }
};



export const fetchInvoiceMailsRequest = () => {
    return {
        type: ActionTypes.LOAD_INVOICE_MAILS_REQUEST
    }
};

const fetchInvoiceMailsSuccess = (invoice_mails,   meta) => {
    return {
        type: ActionTypes.LOAD_INVOICE_MAILS_SUCCESS,
        invoice_mails,
        meta
    }
};

export const fetchInvoiceMails = (dispatch, token,  page, pagesize, order, asc) => {

        dispatch(fetchAllInvoicesRequest())
        let header = {};
        header["Authorization"] = token;
        header["Accept"] = "application/json";
        header["Content-Type"] = "application/json";

        let config = {};
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.INVOICE_MAILS}/?page=${page}&pagesize=${pagesize}&order=${order}&asc=${asc}`;

        return axios.instance(config)
            .then(response => {

                dispatch(fetchInvoiceMailsSuccess(response.data.invoice_mails,  response.data.meta))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }
            });

}



const fetchInvoiceMailItemsRequest = () => {
    return {
        type: ActionTypes.LOAD_INVOICE_MAIL_ITEMS_REQUEST
    }
};

const fetchInvoiceMailItemsSuccess = (items,   meta) => {
    return {
        type: ActionTypes.LOAD_INVOICE_MAIL_ITEMS_SUCCESS,
        items,
        meta
    }
};

export const fetchInvoiceMailItems = (dispatch, token, id, page, pagesize, order, asc) => {

    dispatch(fetchInvoiceMailItemsRequest())
    let header = {};
    header["Authorization"] = token;
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.INVOICE_MAIL_ITEMS}/?id=${id}&page=${page}&pagesize=${pagesize}&order=${order}&asc=${asc}`;

    return axios.instance(config)
        .then(response => {

            dispatch(fetchInvoiceMailItemsSuccess(response.data.items,  response.data.meta))
        })
        .catch(error => {

            if (error.response === undefined) {
                dispatch(failure("Error", error.message));
            } else {
                dispatch(failure(error.response.data.error, error.response.data.message));
            }
        });

}



const fetchInvoiceMailTemplateListRequest = () => {
    return {
        type: ActionTypes.LOAD_INVOICE_MAIL_TEMPLATE_LIST_REQUEST
    }
};

const fetchInvoiceMailTemplateListSuccess = (invoice_templates, meta) => {
    return {
        type: ActionTypes.LOAD_INVOICE_MAIL_TEMPLATE_LIST_SUCCESS,
        invoice_templates,
        meta
    }
};

export const fetchInvoiceMailTemplateList = (dispatch, token) => {

    dispatch(fetchInvoiceMailTemplateListRequest())
    let header = {};
    header["Authorization"] = token;
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.INVOICE_MAIL_TEMPLATES}`;

    return axios.instance(config)
        .then(response => {

            dispatch(fetchInvoiceMailTemplateListSuccess(response.data.invoice_templates, response.data.meta))
        })
        .catch(error => {

            if (error.response === undefined) {
                dispatch(failure("Error", error.message));
            } else {
                dispatch(failure(error.response.data.error, error.response.data.message));
            }
        });

}

export const fetchInvoiceMailTemplateRequest = () => {
    return {
        type: ActionTypes.LOAD_INVOICE_MAIL_TEMPLATE_REQUEST
    }
};

export const fetchInvoiceMailTemplateSuccess = (mail, subject, name) => {
    return {
        type: ActionTypes.LOAD_INVOICE_MAIL_TEMPLATE_SUCCESS,
        mail,
        subject,
        name
    }
};

export const fetchInvoiceMailTemplate = (dispatch, token, id) => {

    dispatch(fetchInvoiceMailTemplateRequest())
    let header = {};
    header["Authorization"] = token;
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.INVOICE_MAIL_TEMPLATE}/${id}`;

    return axios.instance(config)
        .then(response => {

            dispatch(fetchInvoiceMailTemplateSuccess(response.data.mail, response.data.subject, response.data.name))
        })
        .catch(error => {

            if (error.response === undefined) {
                dispatch(failure("Error", error.message));
            } else {
                dispatch(failure(error.response.data.error, error.response.data.message));
            }
        });

}



export const createInvoiceMailTemplateRequest = () => {
    return {
        type: ActionTypes.CREATE_INVOICE_MAIL_TEMPLATE_REQUEST
    }
};

export const createInvoiceMailTemplateSuccess = (id, mail, subject, name) => {
    return {
        type: ActionTypes.CREATE_INVOICE_MAIL_TEMPLATE_SUCCESS,
        id,
        mail,
        subject,
        name
    }
};

export const createInvoiceMailTemplate = (dispatch, token, info) => {
    return new Promise(function (resolve, reject) {
        dispatch(createInvoiceMailTemplateRequest())

        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.INVOICE_MAIL_TEMPLATES}`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        return axios.instance(config)
            .then(response => {

                dispatch(createInvoiceMailTemplateSuccess(response.data.id,response.data.mail,response.data.subject,response.data.name));
                resolve(response);
            })
            .catch(error => {
                reject(error);
                // if (error.response === undefined) {
                //     dispatch(failure("Error", error.message));
                // } else {
                //     dispatch(failure(error.response.data.error, error.response.data.message));
                // }
            });

    });
}


export const updateInvoiceMailTemplateRequest = () => {
    return {
        type: ActionTypes.UPDATE_INVOICE_MAIL_TEMPLATE_REQUEST
    }
};

export const updateInvoiceMailTemplateSuccess = (mail) => {
    return {
        type: ActionTypes.UPDATE_INVOICE_MAIL_TEMPLATE_SUCCESS,
        mail
    }
};

export function updateInvoiceMailTemplate(dispatch, token, days, info) {

        dispatch(updateInvoiceMailTemplateRequest())

        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.INVOICE_MAIL_TEMPLATE}/${days}`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        return axios.instance(config)
            .then(response => {

                dispatch(updateInvoiceMailTemplateSuccess(response.data.mail))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }

            });


}


export const removeInvoiceMailTemplateRequest = () => {
    return {
        type: ActionTypes.REMOVE_INVOICE_MAIL_TEMPLATE_REQUEST
    }
};

export const removeInvoiceMailTemplateSuccess = (id) => {
    return {
        type: ActionTypes.REMOVE_INVOICE_MAIL_TEMPLATE_SUCCESS,
        id
    }
};

export const removeInvoiceMailTemplate = (dispatch, token, id) => {

    dispatch(removeInvoiceMailTemplateRequest())

    let header = {}
    header["Authorization"] = token
    header["Accept"] = "application/json"
    header["Content-Type"] = "application/json"

    let config = {}
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.INVOICE_MAIL_TEMPLATE}/${id}`;
    config["method"] = "delete";


    return axios.instance(config)
        .then(response => {

            dispatch(removeInvoiceMailTemplateSuccess(id))
        })
        .catch(error => {

            if (error.response === undefined) {
                dispatch(failure("Error", error.message));
            } else {
                dispatch(failure(error.response.data.error, error.response.data.message));
            }

        });


}



/*
* SMS Template
*
 */

const fetchSmsTemplateRequest = () => {
    return {
        type: ActionTypes.LOAD_SMS_TEMPLATE_REQUEST
    }
};

const fetchSmsTemplateSuccess = (sms) => {
    return {
        type: ActionTypes.LOAD_SMS_TEMPLATE_SUCCESS,
        sms
    }
};

export const fetchSmsTemplate = (dispatch,  name_type) => {

    dispatch(fetchSmsTemplateRequest())
    let header = {};
//    header["Authorization"] = token;
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.SMS_TEMPLATE}/${name_type}`;

    return axios.instance(config)
        .then(response => {

            dispatch(fetchSmsTemplateSuccess(response.data.sms))
        })
        .catch(error => {

            if (error.response === undefined) {
                dispatch(failure("Error", error.message));
            } else {
                dispatch(failure(error.response.data.error, error.response.data.message));
            }
        });

}


const updateSmsTemplateRequest = () => {
    return {
        type: ActionTypes.UPDATE_SMS_TEMPLATE_REQUEST
    }
};

const updateSmsTemplateSuccess = () => {
    return {
        type: ActionTypes.UPDATE_SMS_TEMPLATE_SUCCESS
    }
};

export function updateSmsTemplate(dispatch, name_type, info) {

    dispatch(updateSmsTemplateRequest())

    let header = {}

    header["Accept"] = "application/json"
    header["Content-Type"] = "application/json"

    let config = {}
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.SMS_TEMPLATE}/${name_type}`;
    config["method"] = "post";
    config["data"] = JSON.stringify(info);

    return axios.instance(config)
        .then(response => {

            dispatch(updateSmsTemplateSuccess())
        })
        .catch(error => {

            if (error.response === undefined) {
                dispatch(failure("Error", error.message));
            } else {
                dispatch(failure(error.response.data.error, error.response.data.message));
            }

        });


}

/*
    SMS Items
 */

const fetchSmsItemsRequest = () => {
    return {
        type: ActionTypes.LOAD_SMS_ITEMS_REQUEST
    }
};

const fetchSmsItemsSuccess = (items,   meta) => {
    return {
        type: ActionTypes.LOAD_SMS_ITEMS_SUCCESS,
        items,
        meta
    }
};

export const fetchSmsItems = (dispatch, token, page, pagesize, order, asc) => {

    dispatch(fetchSmsItemsRequest())
    let header = {};
    header["Authorization"] = token;
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.SMS_ITEMS}/?page=${page}&pagesize=${pagesize}&order=${order}&asc=${asc}`;

    return axios.instance(config)
        .then(response => {

            dispatch(fetchSmsItemsSuccess(response.data.items,  response.data.meta))
        })
        .catch(error => {

            if (error.response === undefined) {
                dispatch(failure("Error", error.message));
            } else {
                dispatch(failure(error.response.data.error, error.response.data.message));
            }
        });

}



const fetchSmsItemRequest = () => {
    return {
        type: ActionTypes.FETCH_SMS_ITEM_REQUEST
    }
};

const fetchSmsItemSuccess = (status, item) => {
    return {
        type: ActionTypes.FETCH_SMS_ITEM_SUCCESS,
        status,
        item
    }
};

export const fetchSmsItem = (dispatch,  itemId) => {

    dispatch(fetchSmsItemRequest())
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.SMS_ITEM}/${itemId}`;

    return axios.instance(config)
        .then(response => {

            dispatch(fetchSmsItemSuccess(response.data.status, response.data.item))
        })
        .catch(error => {

            throw(error);

        });
}

const sendSmsRequest = () => {
    return {
        type: ActionTypes.SEND_SMS_REQUEST
    }
};

const sendSmsSuccess = (id, status, phone) => {
    return {
        type: ActionTypes.SEND_SMS_SUCCESS,
        id,
        status,
        phone
    }
};

export function sendSms(dispatch, id, info) {

    dispatch(sendSmsRequest())

    let header = {}

    header["Accept"] = "application/json"
    header["Content-Type"] = "application/json"

    let config = {}
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.SMS_NEW_ITEM}/${id}`;
    config["method"] = "post";
    config["data"] = JSON.stringify(info);

    return axios.instance(config)
        .then(response => {

            dispatch(sendSmsSuccess(id, info.status, info.phone))
        })
        .catch(error => {

            if (error.response === undefined) {
                dispatch(failure("Error", error.message));
            } else {
                dispatch(failure(error.response.data.error, error.response.data.message));
            }

        });
}



const removeContactRequest = () => {
    return {
        type: ActionTypes.DELETE_CONTACT_REQUEST
    }
};

const removeContactSuccess = (contact) => {
    return {
        type: ActionTypes.DELETE_CONTACT_SUCCESS,
        contact
    }
};

export const removeContact = (dispatch, token, contactId) => {

    return new Promise(function (resolve, reject) {
        dispatch(removeContactRequest())

        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.CONTACT}/${contactId}`;
        config["method"] = "delete";

        axios.instance(config)
            .then(response => {

                dispatch(removeContactSuccess(response.data.contact))
                resolve(response);
            })
            .catch(error => {
                reject(error);
                // if (error.response === undefined) {
                //     dispatch(failure("Error", error.message));
                // } else {
                //     dispatch(failure(error.response.data.error, error.response.data.message));
                // }
            });
    });
};


const fetchContactsRequest = () => {
    return {
        type: ActionTypes.FETCH_CONTACTS_REQUEST
    }
};

const fetchContactsSuccess = (status, contacts) => {
    return {
        type: ActionTypes.FETCH_CONTACTS_SUCCESS,
        status,
        contacts
    }
};

export const fetchContacts = (dispatch, token, companyId) => {

    dispatch(fetchContactsRequest())
    let header = {};
    header["Authorization"] = token;
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.COMPANY}/${companyId}/contacts`;

    return axios.instance(config)
        .then(response => {

            dispatch(fetchContactsSuccess(response.data.status, response.data.contacts))
        })
        .catch(error => {

            throw(error);

        });
}


/*
create contact
 */

const createContactRequest = () => {
    return {
        type: ActionTypes.CREATE_CONTACT_REQUEST
    }
};

const createContactSuccess = (contact) => {
    return {
        type: ActionTypes.CREATE_CONTACT_SUCCESS,
        contact
    }
};

export const createContact = (dispatch, token, companyId, info) => {

    return new Promise(function (resolve, reject) {
        dispatch(createContactRequest())
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.COMPANY}/${companyId}/contacts`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        axios.instance(config)
            .then(response => {
                dispatch(createContactSuccess(response.data.contact))
                resolve(response);
            })
            .catch(error => {
                reject(error);
                // if (error.response === undefined) {
                //     dispatch(failure("Error", error.message));
                // } else {
                //     dispatch(failure(error.response.data.error, error.response.data.message));
                // }
//                throw(error);
            });
    });
};





const fetchNotesRequest = () => {
    return {
        type: ActionTypes.FETCH_NOTES_REQUEST
    }
};

const fetchNotesSuccess = ( notes) => {
    return {
        type: ActionTypes.FETCH_NOTES_SUCCESS,
        notes
    }
};

export const fetchNotes = (dispatch,  id, noteType) => {

    dispatch(fetchNotesRequest())
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.NOTES}/all?id=${id}&note_type=${noteType}`;

    return axios.instance(config)
        .then(response => {
            dispatch(fetchNotesSuccess( response.data.notes))
        })
        .catch(error => {

            throw(error);

        });
}

const createNoteRequest = () => {
    return {
        type: ActionTypes.CREATE_NOTE_REQUEST
    }
};

const createNoteSuccess = (note) => {
    return {
        type: ActionTypes.CREATE_NOTE_SUCCESS,
        note
    }
};

export const createNote = (dispatch, companyId, info) => {
    return new Promise(function (resolve, reject) {
        dispatch(createNoteRequest())
        let header = {}
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.COMPANY}/${companyId}/notes`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        axios.instance(config)
            .then(response => {

                dispatch(createNoteSuccess(response.data.note))
                resolve(response);
            })
            .catch(error => {
                reject(error);
                // if (error.response === undefined) {
                //     dispatch(failure("Error", error.message));
                // } else {
                //     dispatch(failure(error.response.data.error, error.response.data.message));
                // }
                // throw(error);

            });
    });
};






const removeNoteRequest = () => {
    return {
        type: ActionTypes.DELETE_NOTE_REQUEST
    }
};

const removeNoteSuccess = (note) => {
    return {
        type: ActionTypes.DELETE_NOTE_SUCCESS,
        note
    }
};

export const removeNote = (dispatch,  id) => {

    return new Promise(function (resolve, reject) {
        dispatch(removeNoteRequest())
        let header = {}
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.NOTE}/${id}`;
        config["method"] = "delete";

        axios.instance(config)
            .then(response => {

                dispatch(removeNoteSuccess(response.data.note))
                resolve(response);
            })
            .catch(error => {
                reject(error);
                // if (error.response === undefined) {
                //     dispatch(failure("Error", error.message));
                // } else {
                //     dispatch(failure(error.response.data.error, error.response.data.message));
                // }
                // throw(error);
            });
    });
};



const fetchGcpsRequest = () => {
    return {
        type: ActionTypes.FETCH_GCPS_REQUEST
    }
};

const fetchGcpsSuccess = (status, gcps, notes) => {
    return {
        type: ActionTypes.FETCH_GCPS_SUCCESS,
        status,
        gcps,
        notes
    }
};

export const fetchGcps = (dispatch,  companyId) => {

    dispatch(fetchGcpsRequest())
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.COMPANY}/${companyId}/gcps`;

    return axios.instance(config)
        .then(response => {
            //let p = response.data.gcps.reduce((map, obj) => (map[obj.id] = obj, map), {});
            dispatch(fetchGcpsSuccess(response.data.status, response.data.gcps, response.data.notes))
        })
        .catch(error => {

            throw(error);

        });
}



const removeGcpRequest = () => {
    return {
        type: ActionTypes.DELETE_GCP_REQUEST
    }
};

const removeGcpSuccess = (gcp) => {
    return {
        type: ActionTypes.DELETE_GCP_SUCCESS,
        gcp
    }
};

export const removeGcp = (dispatch,  id) => {

    return new Promise(function (resolve, reject) {
        dispatch(removeGcpRequest())
        let header = {}
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.GCP}/${id}`;
        config["method"] = "delete";

        axios.instance(config)
            .then(response => {

                dispatch(removeGcpSuccess(response.data.gcp))
                resolve(response);
            })
            .catch(error => {
                reject(error);

                // if (error.response === undefined) {
                //     dispatch(failure("Error", error.message));
                // } else {
                //     dispatch(failure(error.response.data.error, error.response.data.message));
                // }
//                throw(error);
            });
    });
};



const verifyGcpRequest = () => {
    return {
        type: ActionTypes.VERIFY_GCP_REQUEST
    }
};

const verifyGcpSuccess = (gcp) => {
    return {
        type: ActionTypes.VERIFY_GCP_SUCCESS,
        gcp
    }
};

export const verifyGcp = (dispatch,  id, info) => {

    return new Promise(function (resolve, reject) {
        dispatch(verifyGcpRequest())
        let header = {}
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.GCP_ADMIN}/${id}/verify`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        axios.instance(config)
            .then(response => {

                dispatch(verifyGcpSuccess(response.data.gcp))
                resolve(response);
            })
            .catch(error => {
                reject(error);

                // if (error.response === undefined) {
                //     dispatch(failure("Error", error.message));
                // } else {
                //     dispatch(failure(error.response.data.error, error.response.data.message));
                // }
//                throw(error);
            });
    });
};


const createGcpRequest = () => {
    return {
        type: ActionTypes.CREATE_GCP_REQUEST
    }
};

const createGcpSuccess = (gcp) => {
    return {
        type: ActionTypes.CREATE_GCP_SUCCESS,
        gcp
    }
};


export const createGcp = (dispatch, token, companyId, info) => {

    return new Promise(function (resolve, reject) {
        dispatch(createGcpRequest())

        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.COMPANY}/${companyId}/gcps`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        return axios.instance(config)
            .then(response => {

                dispatch(createGcpSuccess(response.data.gcp))
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
};


const fetchGcpRequest = () => {
    return {
        type: ActionTypes.FETCH_GCP_REQUEST
    }
};

const fetchGcpSuccess = (status, gcp) => {
    return {
        type: ActionTypes.FETCH_GCP_SUCCESS,
        status,
        gcp
    }
};

export const fetchGcp = (dispatch, token, gcpId) => {

    dispatch(fetchGcpRequest())
    let header = {};
    header["Authorization"] = token;
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.GCP}/${gcpId}`;

    return axios.instance(config)
        .then(response => {

            dispatch(fetchGcpSuccess(response.data.status, response.data.gcp))
        })
        .catch(error => {

            throw(error);

        });
}

const updateGcpRequest = () => {
    return {
        type: ActionTypes.UPDATE_GCP_REQUEST
    }
};

const updateGcpSuccess = (gcp) => {
    return {
        type: ActionTypes.UPDATE_GCP_SUCCESS,
        gcp
    }
};

export const updateGcp = (dispatch, token, id, info) => {

    return new Promise(function (resolve, reject) {
        dispatch(updateGcpRequest())

        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.GCP}/${id}`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        axios.instance(config)
            .then(response => {

                dispatch(updateGcpSuccess(response.data.gcp))
                resolve(response)
            })
            .catch(error => {
                reject(error);
            });
    });
};


const fetchInvoiceMailTemplateLinkRequest = () => {
    return {
        type: ActionTypes.LOAD_INVOICE_MAIL_TEMPLATE_LINK_REQUEST
    }
};

const fetchInvoiceMailTemplateLinkSuccess = (links) => {
    return {
        type: ActionTypes.LOAD_INVOICE_MAIL_TEMPLATE_LINK_SUCCESS,
        links
    }
};

export const fetchInvoiceMailTemplateLink = (dispatch) => {

    dispatch(fetchInvoiceMailTemplateLinkRequest())
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.INVOICE_MAIL_TEMPLATE_LINK}`;

    return axios.instance(config)
        .then(response => {

            dispatch(fetchInvoiceMailTemplateLinkSuccess(response.data.links))
        })
        .catch(error => {

            if (error.response === undefined) {
                dispatch(failure("Error", error.message));
            } else {
                dispatch(failure(error.response.data.error, error.response.data.message));
            }
        });

}



const updateInvoiceMailTemplateLinkRequest = () => {
    return {
        type: ActionTypes.UPDATE_INVOICE_MAIL_TEMPLATE_LINK_REQUEST
    }
};

const updateInvoiceMailTemplateLinkSuccess = (link) => {
    return {
        type: ActionTypes.UPDATE_INVOICE_MAIL_TEMPLATE_LINK_SUCCESS,
        link
    }
};

export const updateInvoiceMailTemplateLink = (dispatch, days, info) => {

    return new Promise(function (resolve, reject) {
        dispatch(updateInvoiceMailTemplateLinkRequest())

        let header = {}
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.INVOICE_MAIL_TEMPLATE_LINK}/${days}`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        axios.instance(config)
            .then(response => {

                dispatch(updateInvoiceMailTemplateLinkSuccess(response.data.link))
                resolve(response)
            })
            .catch(error => {
                reject(error);
            });
    });
};


const fetchProductApplicationsRequest = () => {
    return {
        type: ActionTypes.LOAD_PRODUCT_APPLICATIONS_REQUEST
    }
};

const fetchProductApplicationsSuccess = (requests, meta) => {
    return {
        type: ActionTypes.LOAD_PRODUCT_APPLICATIONS_SUCCESS,
        requests,
        meta
    }
};

export const fetchProductApplications = (dispatch,  page, pagesize) => {

    dispatch(fetchProductApplicationsRequest())
    let header = {};
    //header["Authorization"] = token;
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.PRODUCT_REQUESTS}/?page=${page}&pagesize=${pagesize}`;

    return axios.instance(config)
        .then(response => {

            // let i = 0;
            // response.data.requests.map(r=>{
            //     r.order =i;
            //     i++;
            // });
            // let p = response.data.requests.reduce((map, obj) => (map[obj.id] = obj, map), {});
//            dispatch(fetchProductApplicationsSuccess(p, response.data.meta))

            dispatch(fetchProductApplicationsSuccess(response.data.requests, response.data.meta))

        })


        .catch(error => {

            // if (error.response === undefined) {
            //     dispatch(failure("Error", error.message));
            // } else {
            //     dispatch(failure(error.response.data.error, error.response.data.message));
            // }
            throw(error);

        });
}


const fetchProductImageApplicationsRequest = () => {
    return {
        type: ActionTypes.LOAD_PRODUCT_IMAGE_APPLICATIONS_REQUEST
    }
};

const fetchProductImageApplicationsSuccess = (requests, meta) => {
    return {
        type: ActionTypes.LOAD_PRODUCT_IMAGE_APPLICATIONS_SUCCESS,
        requests,
        meta
    }
};

export const fetchProductImageApplications = (dispatch,  page, pagesize) => {

    dispatch(fetchProductImageApplicationsRequest())
    let header = {};
    //header["Authorization"] = token;
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.PRODUCT_IMAGE_REQUESTS}/?page=${page}&pagesize=${pagesize}`;

    return axios.instance(config)
        .then(response => {

            dispatch(fetchProductImageApplicationsSuccess(response.data.requests, response.data.meta))

        })


        .catch(error => {

            throw(error);

        });
}


const fetchGcpsAllRequest = () => {
    return {
        type: ActionTypes.LOAD_GCPS_REQUEST
    }
};

const fetchGcpsAllSuccess = (gcps, meta) => {
    return {
        type: ActionTypes.LOAD_GCPS_SUCCESS,
        gcps,
        meta
    }
};

export const fetchGcpsAll = (dispatch, barcodeType, size, prefix, name, status, page, pagesize, order, asc) => {

    dispatch(fetchGcpsAllRequest(barcodeType, size, prefix, name, page, pagesize, order, asc));
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.GCPS_ADMIN}/?barcode_type=${barcodeType}&size=${size}&prefix=${prefix}&name=${name}&status=${status}&page=${page}&pagesize=${pagesize}&order=${order}&asc=${asc}`;

    return axios.instance(config)
        .then(response => {

            dispatch(fetchGcpsAllSuccess(response.data.items, response.data.meta))
        })
        .catch(error => {
            //dispatch(finishRequest())
            // if (error.response === undefined) {
            //     dispatch(failure("Error", error.message));
            // } else {
            //     dispatch(failure(error.response.data.error, error.response.data.message));
            // }
        });
}




const fetchHqGcpsRequest = () => {
    return {
        type: ActionTypes.LOAD_HQ_GCPS_REQUEST
    }
};

const fetchHqGcpsSuccess = (gcps, meta) => {
    return {
        type: ActionTypes.LOAD_HQ_GCPS_SUCCESS,
        gcps,
        meta
    }
};

export const fetchHqGcps = (dispatch, page, pagesize) => {

    dispatch(fetchHqGcpsRequest( ));
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.HQ_GCPS}/?page=${page}&pagesize=${pagesize}`;

    return axios.instance(config)
        .then(response => {

            dispatch(fetchHqGcpsSuccess(response.data.items, response.data.meta))
        })
        .catch(error => {
            //dispatch(finishRequest())
            // if (error.response === undefined) {
            //     dispatch(failure("Error", error.message));
            // } else {
            //     dispatch(failure(error.response.data.error, error.response.data.message));
            // }
        });
}



const fetchHqProductsRequest = () => {
    return {
        type: ActionTypes.LOAD_HQ_GCPS_REQUEST
    }
};

const fetchHqProductsSuccess = (products, meta) => {
    return {
        type: ActionTypes.LOAD_HQ_PRODUCTS_SUCCESS,
        products,
        meta
    }
};

export const fetchHqProducts = (dispatch, prefix, page, pagesize) => {

    dispatch(fetchHqProductsRequest( ));
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.HQ_PRODUCTS}/?prefix=${prefix}&page=${page}&pagesize=${pagesize}`;

    return axios.instance(config)
        .then(response => {

            dispatch(fetchHqProductsSuccess(response.data.products, response.data.meta))
        })
        .catch(error => {
            //dispatch(finishRequest())
            // if (error.response === undefined) {
            //     dispatch(failure("Error", error.message));
            // } else {
            //     dispatch(failure(error.response.data.error, error.response.data.message));
            // }
        });
}


const fetchHqBatchesRequest = () => {
    return {
        type: ActionTypes.LOAD_HQ_BATCHES_REQUEST
    }
};

const fetchHqBatchesSuccess = (batches, meta) => {
    return {
        type: ActionTypes.LOAD_HQ_BATCHES_SUCCESS,
        batches,
        meta
    }
};

export const fetchHqBatches = (dispatch,  page, pagesize) => {

    dispatch(fetchHqBatchesRequest( ));
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.HQ_BATCHES}/?page=${page}&pagesize=${pagesize}`;

    return axios.instance(config)
        .then(response => {

            dispatch(fetchHqBatchesSuccess(response.data.batches, response.data.meta))
        })
        .catch(error => {
        });
}


const hqProductGetRequest = () => {
    return {
        type: ActionTypes.HQ_PRODUCT_REQUEST
    }
};

const hqProductGetSuccess = (items, status) => {
    return {
        type: ActionTypes.HQ_PRODUCT_SUCCESS,
        items,
        status
    }
};

export const hqProductGet = (dispatch, info) => {


        dispatch(hqProductGetRequest())
        let header = {}
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.HQ_PRODUCTS}/get`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        axios.instance(config)
            .then(response => {

                dispatch(hqProductGetSuccess(response.data.items, response.data.status))
            })
            .catch(error => {

                if (error.response === undefined) {
                    dispatch(failure("Error", error.message));
                } else {
                    dispatch(failure(error.response.data.error, error.response.data.message));
                }

            });

}


const fetchHqBatchRequest = () => {
    return {
        type: ActionTypes.LOAD_HQ_BATCH_REQUEST
    }
};

const fetchHqBatchSuccess = (resp) => {
    return {
        type: ActionTypes.LOAD_HQ_BATCH_SUCCESS,
        resp
    }
};

export const fetchHqBatch = (dispatch, batchId) => {

    dispatch(fetchHqBatchRequest( ));
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.HQ_BATCH}/?batch=${batchId}`;

    return axios.instance(config)
        .then(response => {

            dispatch(fetchHqBatchSuccess(response.data.response))
        })
        .catch(error => {
            //dispatch(finishRequest())
            // if (error.response === undefined) {
            //     dispatch(failure("Error", error.message));
            // } else {
            //     dispatch(failure(error.response.data.error, error.response.data.message));
            // }
        });
}


const getVerifiedGtinCountRequest = () => {
    return {
        type: ActionTypes.GET_VERIFIED_GTIN_COUNT_REQUEST
    }
};

const getVerifiedGtinCountSuccess = (count) => {
    return {
        type: ActionTypes.GET_VERIFIED_GTIN_COUNT_SUCCESS,
        count
    }
};

export const getVerifiedGtinCount = (dispatch) => {

    dispatch(getVerifiedGtinCountRequest( ));
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.HQ_PRODUCTS}/verified-count`;

    return axios.instance(config)
        .then(response => {

            dispatch(getVerifiedGtinCountSuccess(response.data))
        })
        .catch(error => {
            //dispatch(finishRequest())
            // if (error.response === undefined) {
            //     dispatch(failure("Error", error.message));
            // } else {
            //     dispatch(failure(error.response.data.error, error.response.data.message));
            // }
        });
}


const getVerifiedGcpCountRequest = () => {
    return {
        type: ActionTypes.GET_VERIFIED_GCP_COUNT_REQUEST
    }
};

const getVerifiedGcpCountSuccess = (count) => {
    return {
        type: ActionTypes.GET_VERIFIED_GCP_COUNT_SUCCESS,
        count
    }
};

export const getVerifiedGcpCount = (dispatch) => {

    dispatch(getVerifiedGcpCountRequest( ));
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.HQ_GCPS}/verified-count`;

    return axios.instance(config)
        .then(response => {

            dispatch(getVerifiedGcpCountSuccess(response.data))
        })
        .catch(error => {
            //dispatch(finishRequest())
            // if (error.response === undefined) {
            //     dispatch(failure("Error", error.message));
            // } else {
            //     dispatch(failure(error.response.data.error, error.response.data.message));
            // }
        });
}



const getVerifiedGcpListRequest = () => {
    return {
        type: ActionTypes.GET_VERIFIED_GCP_LIST_REQUEST
    }
};

const getVerifiedGcpListSuccess = (list) => {
    return {
        type: ActionTypes.GET_VERIFIED_GCP_LIST_SUCCESS,
        list
    }
};

export const getVerifiedGcpList = (dispatch) => {

    dispatch(getVerifiedGcpListRequest( ));
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.HQ_GCPS}/verified-list`;

    return axios.instance(config)
        .then(response => {

            dispatch(getVerifiedGcpListSuccess(response.data))
        })
        .catch(error => {
            //dispatch(finishRequest())
            // if (error.response === undefined) {
            //     dispatch(failure("Error", error.message));
            // } else {
            //     dispatch(failure(error.response.data.error, error.response.data.message));
            // }
        });
}

const fetchCountriesRequest = () => {
    return {
        type: ActionTypes.LOAD_COUNTRIES_REQUEST
    }
};

const fetchCountriesSuccess = (countries) => {
    return {
        type: ActionTypes.LOAD_COUNTRIES_SUCCESS,
        countries
    }
};

export const fetchCountries = (dispatch) => {

    dispatch(fetchCountriesRequest( ));
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.COUNTRIES}`;

    return axios.instance(config)
        .then(response => {

            dispatch(fetchCountriesSuccess(response.data.countries))
        })
        .catch(error => {
            //dispatch(finishRequest())
            // if (error.response === undefined) {
            //     dispatch(failure("Error", error.message));
            // } else {
            //     dispatch(failure(error.response.data.error, error.response.data.message));
            // }
        });
}



const hqSendVerifiedGtinsRequest = () => {
    return {
        type: ActionTypes.SEND_VERIFIED_GTINS_REQUEST
    }
};

const hqSendVerifiedGtinsSuccess = () => {
    return {
        type: ActionTypes.SEND_VERIFIED_GTINS_SUCCESS,

    }
};

export const hqSendVerifiedGtins = (dispatch ) => {

    return new Promise(function (resolve, reject) {
        dispatch(hqSendVerifiedGtinsRequest())

        let header = {}
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.HQ_PRODUCTS}/send-verified`;
        config["method"] = "post";

        axios.instance(config)
            .then(response => {

                dispatch(hqSendVerifiedGtinsSuccess())
                resolve(response)
            })
            .catch(error => {
                reject(error);
            });
    });
};



const hqSendVerifiedGcpsRequest = () => {
    return {
        type: ActionTypes.SEND_VERIFIED_GCPS_REQUEST
    }
};

const hqSendVerifiedGcpsSuccess = () => {
    return {
        type: ActionTypes.SEND_VERIFIED_GCPS_SUCCESS,

    }
};

export const hqSendVerifiedGcps = (dispatch, dto ) => {

    return new Promise(function (resolve, reject) {
        dispatch(hqSendVerifiedGcpsRequest())

        let header = {}
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.HQ_GCPS}/send-verified`;
        config["method"] = "post";

        axios.instance(config)
            .then(response => {

                dispatch(hqSendVerifiedGcpsSuccess())
                resolve(response)
            })
            .catch(error => {
                reject(error);
            });
    });
};


const deleteHQGcpsRequest = () => {
    return {
        type: ActionTypes.DELETE_HQ_GCPS_REQUEST
    }
};

const deleteHQGcpsSuccess = (batch) => {
    return {
        type: ActionTypes.DELETE_HQ_GCPS_SUCCESS,
        batch
    }
};

export const deleteHQGcps = (dispatch, arr) => {

    return new Promise(function (resolve, reject) {
        dispatch(deleteHQGcpsRequest())

        let header = {}
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.HQ_GCPS}/remove`;
        config["method"] = "delete";
        config["data"] = JSON.stringify(arr);

        axios.instance(config)
            .then(response => {

                dispatch(deleteHQGcpsSuccess(response.data.batch))
                resolve(response)
            })
            .catch(error => {
                reject(error);
            });
    });
};


const deleteHQProductsRequest = () => {
    return {
        type: ActionTypes.DELETE_HQ_PRODUCTS_REQUEST
    }
};

const deleteHQProductsSuccess = (batch) => {
    return {
        type: ActionTypes.DELETE_HQ_PRODUCTS_SUCCESS,
        batch
    }
};

export const deleteHQProducts = (dispatch, arr) => {

    return new Promise(function (resolve, reject) {
        dispatch(deleteHQProductsRequest())

        let header = {}
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.HQ_PRODUCTS}/remove`;
        config["method"] = "delete";
        config["data"] = JSON.stringify(arr);

        axios.instance(config)
            .then(response => {

                dispatch(deleteHQProductsSuccess(response.data.batch))
                resolve(response)
            })
            .catch(error => {
                reject(error);
            });
    });
};


/*
    SMS Mass Body
 */

const fetchSmsMassBodyRequest = () => {
    return {
        type: ActionTypes.LOAD_SMS_MASS_BODY_REQUEST
    }
};

const fetchSmsMassBodySuccess = (main) => {
    return {
        type: ActionTypes.LOAD_SMS_MASS_BODY_SUCCESS,
        main
    }
};

export const fetchSmsMassBody = (dispatch, id) => {

    dispatch(fetchSmsMassBodyRequest())
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.SMS_MASS_BODY}/${id}`;

    return axios.instance(config)
        .then(response => {

            dispatch(fetchSmsMassBodySuccess(response.data.main))
        })
        .catch(error => {

            if (error.response === undefined) {
                dispatch(failure("Error", error.message));
            } else {
                dispatch(failure(error.response.data.error, error.response.data.message));
            }
        });

}



/*
    SMS Mass Bodies
 */

const fetchSmsMassBodiesRequest = () => {
    return {
        type: ActionTypes.LOAD_SMS_MASS_BODIES_REQUEST
    }
};

const fetchSmsMassBodiesSuccess = (items,   meta) => {
    return {
        type: ActionTypes.LOAD_SMS_MASS_BODIES_SUCCESS,
        items,
        meta
    }
};

export const fetchSmsMassBodies = (dispatch, page, pagesize) => {

    dispatch(fetchSmsMassBodiesRequest())
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.SMS_MASS_BODIES}/?page=${page}&pagesize=${pagesize}`;

    return axios.instance(config)
        .then(response => {

            dispatch(fetchSmsMassBodiesSuccess(response.data.items,  response.data.meta))
        })
        .catch(error => {

            if (error.response === undefined) {
                dispatch(failure("Error", error.message));
            } else {
                dispatch(failure(error.response.data.error, error.response.data.message));
            }
        });

}



/*
    SMS Mass Items
 */

const fetchSmsMassItemsRequest = () => {
    return {
        type: ActionTypes.LOAD_SMS_MASS_ITEMS_REQUEST
    }
};

const fetchSmsMassItemsSuccess = ( items,   meta) => {
    return {
        type: ActionTypes.LOAD_SMS_MASS_ITEMS_SUCCESS,
        items,
        meta
    }
};

export const fetchSmsMassItems = (dispatch, id, name, page, pagesize) => {

    dispatch(fetchSmsMassItemsRequest())
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.SMS_MASS_BODY}/${id}/items?n=${name}&page=${page}&pagesize=${pagesize}`;

    return axios.instance(config)
        .then(response => {

            dispatch(fetchSmsMassItemsSuccess( response.data.items,  response.data.meta))
        })
        .catch(error => {

            if (error.response === undefined) {
                dispatch(failure("Error", error.message));
            } else {
                dispatch(failure(error.response.data.error, error.response.data.message));
            }
        });

}



const getSmsMassItemsCheckedCountRequest = () => {
    return {
        type: ActionTypes.GET_SMS_MASS_ITEMS_CHECKED_COUNT_REQUEST
    }
};

const getSmsMassItemsCheckedCountSuccess = (count) => {
    return {
        type: ActionTypes.GET_SMS_MASS_ITEMS_CHECKED_COUNT_SUCCESS,
        count,
    }
};

export const getSmsMassItemsCheckedCount = (dispatch, id) => {

    dispatch(getSmsMassItemsCheckedCountRequest())
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.SMS_MASS_ITEMS}/${id}/checked`;

    return axios.instance(config)
        .then(response => {

            dispatch(getSmsMassItemsCheckedCountSuccess(response.data))
        })
        .catch(error => {

            if (error.response === undefined) {
                dispatch(failure("Error", error.message));
            } else {
                dispatch(failure(error.response.data.error, error.response.data.message));
            }
        });

}

export const checkSession = () => {
    return (dispatch) => {
        return axios.instance({
            method: 'get',
            url: `${EndpointsExpress.COMPANIES_V1}/check-session`,
            withCredentials: true
        })
            .then(response => {
                if (response.data.isLoggedIn) {
                    localStorage.setItem('auth_express', "true");
                    dispatch(receiveLoginExpress());
                } else {
                    localStorage.removeItem('auth_express');
                }
                return response.data;
            })
            .catch(err => console.log("Check session error:", err));
    };
};

