import React, { createContext, useReducer, useContext } from 'react';

const SmsMassContext = createContext();

const initialState = {
    items: [],
    meta: {
        totalPages: 0,
        totalElements: 0,
        pagesize:25
    },
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_ITEMS':
            return {
                ...state,
                items: action.items,
                meta: action.meta,
            };
        case 'ADD_ITEM':
            let newMeta = {...state.meta};
            newMeta.totalElements = newMeta.totalElements + 1;
            newMeta.totalPages = newMeta.totalElements/newMeta.pagesize;
            return {
                ...state,
                items: [action.item, ...state.items],
                meta:newMeta,
            };
        case 'UPDATE_ITEM':
            return {
                ...state,
                items: state.items.map(item => item.id === action.item.id ? action.item : item),
            };
        default:
            return state;
    }
};

export const SmsMassProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const setItems = (items, meta) => {
        dispatch({ type: 'SET_ITEMS', items, meta });
    };

    const addItem = (item) => {
        dispatch({ type: 'ADD_ITEM', item });
    };

    const updateItem = (item) => {
        dispatch({ type: 'UPDATE_ITEM', item });
    };

    return (
        <SmsMassContext.Provider value={{ state, setItems, addItem, updateItem }}>
            {children}
        </SmsMassContext.Provider>
    );
};

export const useSmsMassContext = () => {
    return useContext(SmsMassContext);
};