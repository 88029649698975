/**
 * Created by gantushig on 11/6/15.
 */

import {Link} from "react-router-dom";
import React, {useEffect} from "react";
import {useSmsMassContext} from './context/SmsMassContext';
import qs from "query-string";
import {EndpointsExpress, Page} from "./Constants";
import ReactPaginate from "react-paginate";
import history from "./history";
import axios from "./axiosConfig";


const SmsMassBodiesPage = ({
                          location: {search}
                      }) => {
    const { state, setItems } = useSmsMassContext();
    const {items, meta } = state;
    let parsed = qs.parse(search);
    let page = parsed[Page.PAGE];
    let pagesize = parsed[Page.PAGESIZE];

    if (page === undefined) {
        page = "0";
    }

    if (pagesize === undefined) {
        pagesize = "25";
    }



    useEffect(() => {
        const fetchAll= async (page, pagesize)=>{
            try {
                let header = {};
                header["Accept"] = "application/json";
                header["Content-Type"] = "application/json";

                let config = {};
                config["headers"] = header;
                config["url"] = `${EndpointsExpress.SMS_MASS_BODIES}/?page=${page}&pagesize=${pagesize}`;
                const response = await axios.instance(config);
                const {items, meta} = response.data;
                setItems(items, meta);
            } catch (error) {
                console.log("eroror")
            }
        }
        console.log("fetchall called");
        fetchAll(page, pagesize);
    }, [ page, pagesize]);

    const handlePageClick = (data) => {
        let parsed = qs.parse(search);
        parsed[Page.PAGE] = data.selected;
        history.push("?" + qs.stringify(parsed))
    };

    let counter = Number(page) * Number(pagesize)
    const displayItems = items.map((mail) => {
           counter++;
           return (
                <tr key={mail.id}>
                    <td><Link to={`/sms-mass-body/${mail.id}`}>{counter}</Link></td>
                    <td>
                        <Link to={`/sms-mass-body/${mail.id}`}>
                            {mail.name}
                        </Link>

                    </td>
                    <td>{mail.created}</td>
                    <td>{mail.status}</td>
                </tr>
            )
        });

    return (
        <div className="pt-2 pr-2 pl-2 bg-white">

            <Link to="/sms-mass-body-create" className="block m-2 p-2 w-16 border-gray-300 bg-green-500 text-white font-bold rounded">
                <span className="text-white hover:text-blue-800 visited:text-white">Create New</span>
            </Link>
            <br />

            Нийт : {meta.totalElements}
            <br/>
            <table className="table is-fullwidth">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Created</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                {displayItems}
                </tbody>
            </table>

            <nav className="pagination">
                <ReactPaginate previousLabel={"Өмнөх"}
                               nextLabel={"Дараах"}
                               initialPage={page}
                               breakLabel={<a href="">...</a>}
                               breakClassName={"pagination-ellipsis"}
                               pageCount={meta.totalPages}
                               marginPagesDisplayed={2}
                               pageRangeDisplayed={5}
                               activeLinkClassName={"is-current"}
                               pageLinkClassName={"pagination-link"}
                               onPageChange={(e) => handlePageClick(e)}
                               previousClassName={"pagination-previous"}
                               nextClassName={"pagination-next"}
                               containerClassName={"pagination-list"}
                />
            </nav>

        </div>
    )

}


export default SmsMassBodiesPage;


