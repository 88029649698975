import React from "react";


const SmsMassItemsPageHeader = ({main}) => {
    return (
        <div className="sms-texts-container p-4 max-w-md">
            <div className="space-y-6">
                {/* Name */}
                <div className="flex items-center">
                    <span className="font-semibold w-full">{main.name}</span>

                </div>

                {/* Sms Mobi */}
                <div className="relative flex justify-center">
      <span className="tag-red absolute top-0 transform -translate-y-1/2">
        Sms Mobi [{main.sms ? main.sms.length : 0} үсэг]
      </span>
                    <span className="glow-red block w-full pt-6 text-left">
                            <div className="h-4"></div>
                        {main.sms}</span>
                </div>

                {/* Sms Unitel */}
                <div className="relative flex justify-center">
      <span className="tag-green absolute top-0 transform -translate-y-1/2">
        Sms Unitel [{main.sms2 ? main.sms2.length : 0} үсэг]
      </span>

                    <span className="glow-green block w-full pt-6 text-left">
                            <div className="h-4"></div>
                        {main.sms2}</span>
                </div>

                {/* Sms Skytel */}
                <div className="relative flex justify-center">
      <span className="tag-blue absolute top-0 transform -translate-y-1/2">
        Sms Skytel [{main.sms3 ? main.sms3.length : 0} үсэг]
      </span>
                    <span className="glow-blue block w-full pt-12 text-left">
                            <div className="h-4"></div>
                        {main.sms3}</span>
                </div>

                {/* Status */}
                <div className="flex items-center">
                    <span className="font-semibold w-32 text-sm">Status</span>
                    <span>{main.status}</span>
                </div>

                {/* Created */}
                <div className="flex items-center">
                    <span className="font-semibold w-32 text-sm">Created</span>
                    <span>{main.created}</span>
                </div>
            </div>
        </div>
    )
}

export default SmsMassItemsPageHeader
