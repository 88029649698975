/**
 * Created by gantushig on 11/6/15.
 */

import {Link, useHistory} from "react-router-dom";
import React, {useEffect, useReducer, useState} from "react";
import {fetchSmsMassItems} from "./actions/actions";
import qs from "query-string";
import {ActionTypes, EndpointsExpress, Page, SOCKET_URL} from "./Constants";
import ReactPaginate from "react-paginate";
//import history from "./history";
import {toast} from "react-toastify";
import {confirm} from "react-confirm-box";
import axios from "./axiosConfig";
import './SmsMassItemsPage.css'
import SmsMassItemsPageHeader from "./SmsMassItemsPageHeader";
import {io} from "socket.io-client";

const socket = io(SOCKET_URL); // Adjust to your backend URL
const initialState = {
    main: {},
    meta:{},
    items: [],
    loading: false,
    totalPages: 0,
    totalElements: 0,
    checkedCount: 0,
    checkedChanged: false,
    mobiCount: 0,
    uniCount: 0,
    skyCount: 0,

};

const reducer = (state, action) => {
    switch (action.type) {

        case ActionTypes.LOAD_SMS_MASS_ITEMS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.LOAD_SMS_MASS_ITEMS_SUCCESS:
            return {
                ...state,
                loading: false,
                meta:action.meta,
                main: action.meta.campaign,
                items: action.items,
                totalElements: action.meta.totalElements,
                totalPages: action.meta.totalPages,
                checkedCount: action.meta.selected_count,
                mobiCount: action.meta.selected_count_mobi,
                uniCount: action.meta.selected_count_uni,
                skyCount: action.meta.selected_count_sky,
            }
        case ActionTypes.GET_SMS_MASS_ITEMS_CHECKED_COUNT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.GET_SMS_MASS_ITEMS_CHECKED_COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                checkedCount: action.count
            }
        case ActionTypes.FETCH_SMS_ITEM_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.FETCH_SMS_ITEM_SUCCESS: {
            const ps = state.items.map(a => ({...a}));

            const itemIndex = ps.findIndex(p => p.id === action.item.id);
            if (itemIndex !== -1) {
                ps[itemIndex] = action.item;

            }

            return {
                ...state,
                loading: false,
                items: ps,
            }
        }
        case ActionTypes.SEND_SMS_SUCCESS:

            let status = action.status;
            if (action.status === 'SEND') {
                status = "SENDING";
            } else if (action.status === 'CANCEL') {
                status = "CANCELLED";
            }
            const ps = state.items.map(a => ({...a}));

            const itemIndex = ps.findIndex(p => p.id === action.id);
            if (itemIndex !== -1) {
                ps[itemIndex].status = status;
                ps[itemIndex].phone = action.phone;
            }
            return {
                ...state,
                items: ps,
            }
        case  'ITEM_CHECKED_CHANGED': {
            return {
                ...state,
                checkedCount: action.count,
            }

        }
        default:
            return state;
    }
};


const itemCheckedChanged = (count) => {
    return {
        type: 'ITEM_CHECKED_CHANGED',
        count
    }
}

const SmsMassItemsPage = ({ match: {params: {id}}, location: {search} }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [localSelectAll, setLocalSelectAll] = useState(false); // Current page selection
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [progress, setProgress] = useState({ progress: 0, total: 0, processed: 0 });

    const {
        main, items, totalPages, totalElements, mobiCount, uniCount, skyCount
    } = state;
    const campaign = state.meta?.campaign || {};

    // Local state for the input
// Initialize name from URL
    const [name, setName] = useState(() => {
        const parsed = qs.parse(search, { ignoreQueryPrefix: true });
        return parsed["n"] || null;
    });

    // Parse URL query params once per render
    const parsed = qs.parse(search, { ignoreQueryPrefix: true });
    const page = parsed[Page.PAGE] || "0";
    const pagesize = parsed[Page.PAGESIZE] || "25";

    // Sync name with URL when search changes externally
    useEffect(() => {
        const parsed = qs.parse(search, { ignoreQueryPrefix: true });
        const urlName = parsed["n"] || null;
        if (urlName !== name) {
            setName(urlName); // Update name if URL changes (e.g., back/forward)
        }
    }, [search]);


    useEffect(() => {
        console.log("useEffect running with:", { name, page, pagesize, id })
        fetchSmsMassItems(dispatch, id, name, page, pagesize);
    }, [ page, pagesize, id,dispatch]);

    useEffect(() => {
        if (items.length > 0) {
            let test =items.every(item => item.checked)
            setLocalSelectAll(test);
        }
    }, [items]);
    useEffect(() => {
        if (id) {
            socket.on(`campaign-progress-${id}`, (data) => {
                setProgress(data);
            });

            return () => {
                socket.off(`campaign-progress-${id}`);
            };
        }
    }, [id]);

    const handleCheckAll = async (e) => {
        const checked = e.target.checked;
        setLocalSelectAll(checked);
        setIsLoading(true);

        const itemIds = items.map(item => item.id);
        try {
            await axios.instance({
                method: "post",
                url: `${EndpointsExpress.SMS_MASS_BODY}/${id}/select-page`,
                data: {
                    itemIds,
                    selected: checked,
                    selectionType: 'PAGE',
                    nameFilter: name
                }
            });
            fetchSmsMassItems(dispatch, id, name, page, pagesize);
        } catch (e) {
            toast.error("Failed to update selection");
            setLocalSelectAll(!checked);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSelectAllAcrossPages = async () => {
        setIsLoading(true);
        try {
            await axios.instance({
                method: "post",
                url: `${EndpointsExpress.SMS_MASS_BODY}/${id}/select-all`,
                data: {
                    selected: true,
                    selectionType: 'ALL',
                    nameFilter: name
                }
            });
            fetchSmsMassItems(dispatch, id, name, page, pagesize);
        } catch (e) {
            toast.error("Failed to select all");
        } finally {
            setIsLoading(false);
        }
    };

    const handleClearSelection = async () => {
        setIsLoading(true);
        try {
            await axios.instance({
                method: "post",
                url: `${EndpointsExpress.SMS_MASS_BODY}/${id}/clear-selection`, // New endpoint for clarity
                data: JSON.stringify({
                    selectionType: 'NONE'
                })
            });
            setLocalSelectAll(false);
            fetchSmsMassItems(dispatch, id, name, page, pagesize);
        } catch (e) {
            toast.error("Failed to clear selection");
        } finally {
            setIsLoading(false);
        }
        // setIsLoading(true);
        // try {
        //     await axios.instance({
        //         method: "post",
        //         url: `${EndpointsExpress.SMS_MASS_BODY}/${id}/select-all`,
        //         data: {
        //             selected: false,
        //             selectionType: 'NONE',
        //             nameFilter: name
        //         }
        //     });
        //     setLocalSelectAll(false);
        //     fetchSmsMassItems(dispatch, id, name, page, pagesize);
        // } catch (e) {
        //     toast.error("Failed to clear selection");
        // } finally {
        //     setIsLoading(false);
        // }
    };

    const handleIndividualChange = async (event, item_id) => {
        const checked = event.target.checked;
        setLocalSelectAll(false);

        setIsLoading(true);
        try {
            await axios.instance({
                method: "post",
                url: `${EndpointsExpress.SMS_MASS_ITEM}/${item_id}/select`,
                data: {
                    selected: checked,
                    massId: id,
                    selectionType: 'MANUAL',
                    nameFilter: name
                }
            });
            fetchSmsMassItems(dispatch, id, name, page, pagesize);
        } catch (e) {
            toast.error("Failed to update item");
        } finally {
            setIsLoading(false);
        }
    };

    const handlePageClick = (data) => {
        let parsed = qs.parse(search, { ignoreQueryPrefix: true });
        parsed[Page.PAGE] = data.selected;
        history.push("?" + qs.stringify(parsed))
    };

    const handleSearch = (data) => {
        let parsed = qs.parse(search, { ignoreQueryPrefix: true });
        parsed["n"] = name;
        parsed[Page.PAGE] = "0";
        history.push("?" + qs.stringify(parsed));
        // Fetch immediately after updating URL
        fetchSmsMassItems(dispatch, id, name, "0", pagesize);
    };

    const handlePrepare = () => {
        async function prepare() {

            let header = {}

            header["Accept"] = "application/json"
            header["Content-Type"] = "application/json"

            let config = {}
            config["headers"] = header;
            config["url"] = `${EndpointsExpress.SMS_MASS_BODY}/${id}/prepare`;
            config["method"] = "post";

            try {
                const response = await axios.instance(config);
            } catch (e) {
                alert(e)
            }
        }

        console.log('prepare');
        prepare();
        toast.success("Success!");
    };

    const optionsWithLabelChange = {
        closeOnOverlayClick: false,
        labels: {
            confirmable: "Send",
            cancellable: "Cancel"
        }
    };
    //
    // const handleChange = async (event, item_id) => {
    //
    //     async function check() {
    //         let dto = {
    //             checked: event.target.checked
    //         };
    //
    //         let header = {}
    //
    //         header["Accept"] = "application/json"
    //         header["Content-Type"] = "application/json"
    //
    //         let config = {}
    //         config["headers"] = header;
    //         config["url"] = `${EndpointsExpress.SMS_MASS_ITEM}/${item_id}/check`;
    //         config["method"] = "post";
    //         config["data"] = JSON.stringify(dto);
    //         try {
    //             const response = await axios.instance(config);
    //             dispatch(itemCheckedChanged(response.data.count));
    //         } catch (e) {
    //             alert(e)
    //         }
    //     }
    //
    //     check();
    // }

    const handleSend = async () => {
        const result = await confirm("SMS-ууд илгээх үү?", optionsWithLabelChange);
        if (!result) return;

        setIsLoading(true);
        try {
            const response = await axios.instance({
                method: "post",
                url: `${EndpointsExpress.SMS_MASS_BODY}/${id}/send`,
                data: JSON.stringify({}) // Remove nameFilter
            });
            toast.success(`Success! Queing messages and starting to send.`);
            fetchSmsMassItems(dispatch, id, name, page, pagesize);
            setLocalSelectAll(false);
        } catch (e) {
            toast.error("Failed to send");
        } finally {
            setIsLoading(false);
        }
    };

    //
    // const handleSend = async () => {
    //
    //     // async function send() {
    //     //     let header = {}
    //     //
    //     //     header["Accept"] = "application/json"
    //     //     header["Content-Type"] = "application/json"
    //     //
    //     //     let config = {}
    //     //     config["headers"] = header;
    //     //     config["url"] = `${EndpointsExpress.SMS_MASS_BODY}/${id}/send`;
    //     //     config["method"] = "post";
    //     //
    //     //
    //     //     try {
    //     //         const response = await axios.instance(config);
    //     //     } catch (e) {
    //     //         alert(e)
    //     //     }
    //     // }
    //     //
    //     //
    //     // if (result) {
    //     //     console.log('send');
    //     //     //TODO uncomment
    //     //     //send();
    //     //     toast.success("Success!");
    //     // } else {
    //     //     console.log('cancelled');
    //     // }
    // };

    const isCampaignDone = campaign.status === 'SENT' || campaign.status === 'COMPLETED' || campaign.status === 'FAILED';
    const allItemsSelected = campaign.selection_type === 'ALL' && campaign.selected_count > 0 && (!name || campaign.selected_count === items.length + (totalElements - items.length));
    //const allItemsSelected = campaign.selection_type === 'ALL' && campaign.selected_count === totalElements && (!name || name === campaign.name_filter);

//    let counter = Number(page) * Number(pagesize)
    // const displayItems = items.map(
    //     (mail) => {
    //         counter++;
    //         return (
    //             <tr key={mail.id}>
    //                 <td>
    //                     <input
    //                         type="checkbox"
    //                         checked={
    //                             selectAllAcrossPages || // All items selected
    //                             localSelectAll || // Current page selected
    //                             selectedItems[mail.id] // Individual selection
    //                         }
    //                         onChange={(e) => handleIndividualChange(e, mail.id)}
    //                     />
    //                 {/*    <input*/}
    //                 {/*    type="checkbox"*/}
    //                 {/*    defaultChecked={mail.checked}*/}
    //                 {/*    onChange={(e) => handleChange(e, mail.id)}*/}
    //                 {/*/>*/}
    //                 </td>
    //
    //                 <td>{counter}</td>
    //                 <td>
    //                     {mail.company_name}
    //                 </td>
    //                 <td>
    //                     {mail.contact_name} {mail.contact_surname}
    //                 </td>
    //                 <td>{mail.phone}</td>
    //                 <td>{mail.sent}</td>
    //             </tr>
    //         )
    //     });

    const handleChangeName = (e) => {
        setName(e.target.value);
    };



    return (
        <div className="pt-2 pr-2 pl-2 bg-white">
            {isLoading && <div className="loading-overlay">Loading...</div>}
            <SmsMassItemsPageHeader main={campaign} />
            <div>
                <p>Campaign ID: {id}</p>
                <p>Progress: {progress.progress}%</p>
                <p>Sent: {progress.processed} / {progress.total}</p>
                <progress value={progress.progress} max="100" />
            </div>
            <div className="my-4">
                {main.status === "DRAFT" &&
                    <button onClick={handlePrepare}
                            className="p-2 mr-2 w-24 border-gray-300 bg-purple-500  text-white font-bold rounded">
                        <span className="text-white hover:text-blue-800 visited:text-purple-600">Prepare</span>
                    </button>
                }
                {main.status !== "SENT" &&
                    <Link to={`/sms-mass-body-edit/${main.id}`}
                          className="p-2 w-24 border-gray-300 bg-blue-500  text-white font-bold  rounded">
                        <span className="text-white hover:text-blue-800 visited:text-purple-600">Өөрчлөх</span>
                    </Link>
                }
            </div>
            {main.status === "PREPARED" &&
                <div>
                    <div className="mt-12 field">
                        <label className="label">Компаний Нэр</label>
                        <div className="field-body ">
                            <div className="control ">
                                <input className="input" type="text" value={name} style={{width: '400px'}}
                                       onChange={handleChangeName} placeholder="Компаний Нэр Оруулах"/>
                            </div>
                        </div>
                    </div>
                    <button onClick={handleSearch}
                            className="m-2 p-2 w-24 border-gray-300 bg-purple-500  text-white font-bold  rounded"><span
                        className="text-white hover:text-blue-800 visited:text-purple-600">Search</span></button>
                </div>
            }

            {main.status === "PREPARED" && ( campaign.selected_count > 0 ) &&  (
                        <div className="action-buttons">
                            <button
                                onClick={handleSend}
                                className="m-2 p-4 w-24 border-gray-300 bg-orange-500 text-white font-bold rounded"
                                disabled={isLoading}
                            >
                                <span className="text-white text-xl">Send</span>
                            </button>
                            <button
                                onClick={handleClearSelection}
                                className="m-2 p-4 w-24 border-gray-300 bg-gray-500 text-white font-bold rounded"
                                disabled={isLoading}
                            >
                                <span className="text-white text-xl">Clear</span>
                            </button>
                        </div>
            )}

            <div>
                <div>Нийт: {totalElements}</div>
                {campaign.selected_count > 0 && (
                    <div className="selection-summary">
                        Selected: <span className="text-base font-bold">{campaign.selected_count}</span> total
                        (UNI: {campaign.selected_count_uni}, MOBI: {campaign.selected_count_mobi}, SKY: {campaign.selected_count_sky})
                    </div>
                )}
                {totalElements > 0 && (
                    <div className="select-all-notice">
                        {/* Page-level message: hide if all items selected */}
                        {!allItemsSelected && items.length > 0 && (
                            <>
                                {items.some(item => item.checked) && !items.every(item => item.checked) && (
                                    <span>{items.filter(item => item.checked).length} of {items.length} items on this page selected. </span>
                                )}
                                {items.every(item => item.checked) && (
                                    <span>All {items.length} items on this page selected. </span>
                                )}
                            </>
                        )}
                        {/* Campaign-level message */}
                        {totalElements > items.length && (
                            <>
                                {allItemsSelected ? (
                                    <span>All {totalElements} items selected{campaign.name_filter ? ` (filtered by "${campaign.name_filter}")` : ''}.</span>
                                ) : (
                                    <button onClick={handleSelectAllAcrossPages} disabled={isLoading || isCampaignDone}>
                                        Select all {totalElements} items
                                    </button>
                                )}
                            </>
                        )}
                        {/* Clear selection */}
                        {campaign.selected_count > 0 && (
                            <button onClick={handleClearSelection} className="ml-2 clear-selection-btn" disabled={isLoading || isCampaignDone}>
                                Clear Selection
                            </button>
                        )}
                    </div>
                )}
            </div>

            <table className="table is-fullwidth">
                <thead>
                <tr>
                    <th>
                        <input
                            type="checkbox"
                            checked={localSelectAll}
                            onChange={handleCheckAll}
                            disabled={isLoading || isCampaignDone}
                        />
                    </th>
                    <th>#</th>
                    <th>Компани</th>
                    <th>Name</th>
                    <th>Утас</th>
                    <th>Sent</th>
                </tr>
                </thead>
                <tbody>
                {items.map((mail, index) => (
                    <tr key={mail.id} className={mail.status === 'NOT_SENT' ? 'not-sent' : ''}>
                        <td>
                            <input
                                type="checkbox"
                                checked={mail.checked}
                                onChange={(e) => handleIndividualChange(e, mail.id)}
                                disabled={isLoading || isCampaignDone}
                            />
                        </td>
                        <td>{Number(page) * Number(pagesize) + index + 1}</td>
                        <td>{mail.company_name}</td>
                        <td>{mail.contact_name} {mail.contact_surname}</td>
                        <td>{mail.phone}</td>
                        <td>{mail.sent}</td>
                        {/*? 'Yes' : 'No'} ({mail.status})*/}
                    </tr>
                ))}
                </tbody>
            </table>

            <nav className="pagination">
                <ReactPaginate previousLabel={"Өмнөх"}
                               nextLabel={"Дараах"}
                               initialPage={page}
                               breakLabel={<a href="">...</a>}
                               breakClassName={"pagination-ellipsis"}
                               pageCount={totalPages}
                               marginPagesDisplayed={2}
                               pageRangeDisplayed={5}
                               activeLinkClassName={"is-current"}
                               pageLinkClassName={"pagination-link"}
                               onPageChange={(e) => handlePageClick(e)}
                               previousClassName={"pagination-previous"}
                               nextClassName={"pagination-next"}
                               containerClassName={"pagination-list"}
                />
            </nav>

        </div>
    )

}


export default SmsMassItemsPage


